/* (t)easer (w)idget (l)ist */
.tik4-twl {
  /* padding: 0 var(--t4-ev-p-x) 0; */
  width: 100%;
  --t4-twl-spacing: var(--t4-sp-3);
  --t4-twl-icon-size: .875em;
}

/* .tik4-twl-icon {
  flex: 0 0 .6em;
  max-width: .6em;
  height: .6em;
  fill: transparent;
} */

.tik4-twl__list {
  display: flex;
  flex-direction: column;
  gap: var(--t4-twl-spacing);
}

.tik4-twl__item {
  /* position: relative; */
}

/* .tik4-twl__item:before {
  content: '';
  position: absolute;
  top: auto;
  left: -.825em;
  transform: translateY(.6em);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: currentColor;
} */

.tik4-twl__c {
  font-size: var(--t4-txt-base);
  cursor: pointer;
  /* grid-template-columns: var(--t4-sp-6) 1fr; */
  grid-template-columns: var(--t4-twl-icon-size) 1fr;
  grid-template-rows: auto auto;
  column-gap: var(--t4-sp-1);
  transition: color var(--t4-duration), filter var(--t4-duration);

}
a.tik4-twl__c.tik4-twl__c {
  text-decoration: none;
  display: grid;
  color: inherit;
  &:hover {
    color: var(--t4-tw-link);
    filter: var(--t4-hover-saturate);
  }
  &:active {
    color: var(--t4-tw-link);
    filter: var(--t4-active-saturate);
  }
}

.tik4-tw--tl .tik4-twl__c {
  column-gap: var(--t4-sp-2);
}


/* Timeline */
.tik4-twl__tl {
  position: relative;
  grid-column: 1 / 2;
  display: none;
}
.tik4-twl__tl--1 {
  grid-row: 1 / 2;
}
.tik4-twl__tl--2 {
  grid-row: 2 / -1;
}
.tik4-twl__tl__b {
  position: absolute;
  left: calc(50% - 0.5px);
  top: 0;
  width: 1px;
  bottom: 0;
  background: var(--t4-tw-tl-c);
}

.tik4-twl__tl--2 .tik4-twl__tl__b {
  bottom: calc(-1 * var(--t4-twl-spacing));
}

/* Header */
.tik4-twl__h {
  grid-row: 1 / 2;
  grid-column: 2 / -1;
  display: flex;
}

.tik4-twl__h__c {

}

/* Datetime */

.tik4-twl__dt {
  font-size: var(--t4-txt-sm);
  color: var(--t4-tw-muted);
}


.tik4-twl__dt__d {
  white-space: nowrap;
  overflow-x: hidden;
  overflow-x: clip;
  text-overflow: ellipsis;
}

.tik4-twl__dt__div {
  margin-right: .275em;
}

.tik4-twl__dt__t {
  white-space: nowrap;
}

/* Content */
.tik4-twl__content {
  --t4-twl-c-l: 3;
  @extend %tik4-break-safe;
  font-weight: var(--t4-fw-b);
  grid-row: 2 / 3;
  grid-column: 2 / 3;

  display: -webkit-box;
  -webkit-line-clamp: var(--t4-twl-c-l);
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: var(--t4-txt-lh);
  max-height: calc(1em * var(--t4-txt-lh) * var(--t4-twl-c-l));

}

.tik4-twl__item:last-child .tik4-twl__content {
  margin-bottom: 0;
}

.tik4-twl__item:first-child .tik4-twl__tl--1 .tik4-twl__tl__b {
  display: none;
}
.tik4-twl__item:first-child .tik4-twl__tl--2 .tik4-twl__tl__b {
  top: calc(var(--t4-twl-icon-size) / 2);
}

.tik4-twl__item:last-child .tik4-twl__tl--2 .tik4-twl__tl__b {
  background-color: transparent;
  background-image: linear-gradient(180deg, var(--t4-tw-tl-c) 0%, transparent 100%);
  bottom: 0;
}

.tik4-twl__tli {

  width: 100%;
  height: 100%;

  background: var(--t4-tw-tl-c);
  border-radius: .99em;
}


.tik4-twl__icons {
  position: relative;
  grid-row: 2 / -1;
  grid-column: 1 / 2;
  display: flex;
  justify-content: center;
  /* align-items: center; */
}

.tik4-twl__icon--wi {
  & .tik4-twl__tli {
    display: none;
  }
}

.tik4-twl__icons-container {
  background: var(--t4-tw-bg);
  display: flex;
  justify-content: center;
  position: relative;
  width: var(--t4-twl-icon-size);
  height: var(--t4-twl-icon-size);
  flex: 0 0 var(--t4-twl-icon-size);
  outline: 1px solid var(--t4-tw-bg);
  top: calc(-1 * 0.519 * var(--t4-twl-icon-size) + 0.654em );
  /* &:not(:has(.tik4-twl__icon)) {
    --t4-twl-icon-size: .4em;
  } */
}

.tik4-twl__item:not(.tik4-twl__item--wi) .tik4-twl__icons-container {
  --t4-twl-icon-size: .375em;
}

.tik4-tw--tl .tik4-twl__icons-container:not(:has(.tik4-twl__icon)) {
  --t4-twl-icon-size: .5em;
}

.tik4-twl__icon {
  display: block;
  width: var(--t4-twl-icon-size);
  height: var(--t4-twl-icon-size);
  flex: 0 0 var(--t4-twl-icon-size);
  position: relative;
  /* top: .2em; */
   /* top: calc((var(--t4-twl-icon-size)/2 + 0.2em) - var(--t4-twl-icon-size)/2); */
  /*top: calc((var(--t4-twl-icon-size) + 0.1em)/2); */
  /* top: calc(); */
  background: var(--t4-tw-bg);
}
