.tik4-chapters {
  margin-bottom: var(--t4-sp-8);
  &:not(:first-child) {
    margin-top: var(--t4-sp-8);
  }
}

.tik4-chapter-icon {
  flex: 0 0 .6em;
  max-width: .6em;
  height: .6em;
  fill: transparent;
}

.tik4-chapters__label {
  font-size: var(--t4-txt-sm);
  color: var(--t4-muted);
}

.tik4-chapters__item {
  margin-top: var(--t4-sp-1);
  /* display: flex;
  align-items: baseline;
  justify-content: space-between; */
}

/* .tik4-chapters__ts {
  font-size: var(--t4-txt-sm);
  color: var(--t4-muted);
  cursor: pointer;
  text-align: right;
} */

h3.tik4-chapters__title {
  font-weight: var(--t4-fw-b);
  font-size: var(--t4-txt-base);
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  display: flex;
  align-items: baseline;
  /* flex: 1 1 0%;
  min-width: 0; */
  /* margin-right: var(--t4-sp-4); */
  &:hover {
    color: var(--t4-secondary);
    filter: var(--t4-hover-saturate);
  }
  &:active {
    color: var(--t4-secondary);
    filter: var(--t4-active-saturate);
  }
}

.tik4-chapters__title__icon {
  margin-right: .5em;
}

.tik4-chapters__title__content {
  min-width: 0;
  @extend %tik4-break-safe;
}
