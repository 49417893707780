/* Emojis */

.tik4-em {
  display: flex;
  gap: var(--t4-sp-3);
}

.tik4-em__em {
  font-size: 1.875em;
  line-height: 1;
  min-height: 1em;
  height: auto;
  max-height: 1.3em;
  display: flex;
  align-items: center;
}

.tik4-em__txt {
  min-width: 0;
  flex-grow: 1;
}

