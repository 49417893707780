/* sl: slick slider */



.tik4-sl {
  position: relative;
  box-sizing: border-box;

  -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
      touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;


  &.slick-loading .slick-slide,
  &.slick-loading .slick-track {
      visibility: hidden;
  }

  & .slick-list {
    overflow: hidden;
    background: var(--t4-sfc-bg);
    border: 1px solid var(--t4-sfc-b);
    border-radius: var(--t4-rounded);
    &:focus {
      outline: 0;
    }
    /* &.dragging {
      cursor: pointer;
      cursor: hand
    } */
  }

  & .slick-track {
      display: flex;
      top: 0;
      left: 0;
  }

  & .slick-slide {
    display: flex;
    align-items: center;
    justify-content: center;
    & .tik4-media-body {
      margin: .5em;
    }
  }

  & .slick-arrow {
    @mixin tik4-button-reset;
    color: inherit;
    min-width: 0;
    z-index: 1;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: var(--t4-sp-2);
    border-radius: 999em;
    outline-offset: calc(var(--t4-sp-2) * -1 + 2px);
    &:hover .tik4-sl__a {
      transform: scale(1.2);
      color: var(--t4-secondary);
      border-color: currentColor;
    }
    &:active .tik4-sl__a {
      transform: scale(1);
      background: var(--t4-secondary);
      /* color: var(--t4-txt--i) */
      color: #FFF;
    }
  }

  & .slick-next {
    transform: translate(0, calc(-50% - 1em));
    right: 0;
  }

  & .slick-prev {
    transform: translate(0, calc(-50% - 1em));
  }
}

.tik4-sl__a {
  background-color: var(--t4-ev-bg);
  border-radius: 999em;
  border: 1px solid var(--t4-sfc-b);
  width: 2em;
  height: 2em;
  transition: all var(--t4-duration);
  box-shadow: 0 0.13em 0.63em 0.13em rgb(94 97 99 / 10%), 0 0.06em 0.19em 0 rgb(94 97 99 / 15%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.tik4-sl__a__s {
  width: 1.25em;
  height: 1.25em;
}

ul.tik4-sl__d.tik4-sl__d {
  display: flex;
  overflow-x: auto;
  justify-content: center;
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.tik4-sl__d > li {
  margin: 0;
  padding: 0;
  width: auto;
}

.tik4-sl__d .tik4-sl__d__d {
  @mixin tik4-button-reset;
  color: inherit;
  padding: var(--t4-sp-3) var(--t4-sp-1);
  border-radius: 999em;
  outline-offset: calc(var(--t4-sp-1) * -1 + 2px);
  min-width: 0;
  display: block;
  &:after {
    transition: all var(--t4-duration);
    content: '';
    display: block;
    width: 7px;
    height: 7px;
    background: var(--t4-sfc-b);
    border-radius: 999em;
  }
  &:hover:after {
    background: var(--t4-secondary);
    transform: scale(1.2);
  }
  &:active:after {
    transform: scale(1);
  }
}

.slick-active .tik4-sl__d__d:after {
  background: currentColor;
}

.tik4-sl__d__d__i {
  display: none;
}
