.tik4-loading-content {
  animation: tik4-loading-content .75s ease-in-out 0s infinite alternate;
}


@keyframes tik4-loading-content {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
