.tik4-live--cards {
  &.tik4-live--timeline {
    --t4-tl-ml: var(--t4-sp-5);
    &.tik4-live--sm {
      --t4-tl-ml: var(--t4-sp-8);
    }
  }
  & .tik4-gs + .tik4-event .tik4-event__timeline::before {
    display: none;
  }
  & .tik4-tag-filter + .tik4-event .tik4-event__timeline::before {
    display: none;
  }
  & .tik4-chapters + .tik4-event .tik4-event__timeline::before {
    display: none;
  }
  & .tik4-sb--m,
  & .tik4-ssb--m {
    background: var(--t4-ev-bg);
    box-shadow: var(--t4-ca-sh);
    border: 0;
    border-radius: 0;
  }
  & .tik4-event {
    background: var(--t4-ev-bg);
    border-top: 0;
    box-shadow: var(--t4-ca-sh);
  }
  & .tik4-event__timeline:before {
    content: '';
    position: absolute;
    left: 0;
    border-right: var(--t4-tl-w) var(--t4-tl-style);
    bottom: 100%;
    opacity: 0.2;
  }
  &.tik4-live--invert-highlight .tik4-event--highlight,
  &.tik4-live--invert-pin .tik4-event--pin{
    & .tik4-event__timeline:before {
      color: var(--t4-txt--i);
    }
  }
  & .tik4-event:not(:first-child) {
    margin-top: var(--t4-ca-m);
    & .tik4-event__timeline {
      &:before {
        top: calc(-1 * var(--t4-ca-m));
      }
      /* top: calc(-1 * var(--t4-ca-m)); */
    }
  }
  & .tik4-chapter {
    border-top: 0;
    padding-bottom: calc(var(--t4-ms-pb) - var(--t4-ca-m));
  }
  & .tik4-event-list .tik4-event:first-child .tik4-event__timeline:before,
  & .tik4-chapter + .tik4-event .tik4-event__timeline:before {
    display: none;
  }

}
