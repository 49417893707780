/* https://github.com/jsx-eslint/eslint-plugin-jsx-a11y/blob/master/docs/rules/anchor-is-valid.md */

button.tik4-link {
  @mixin tik4-button-reset;
  /* @extend %tik4-button-reset; */
}

/* these classes exist only for client styling if needed */

.tik4-link {
  @extend %tik4-link-style;
  display: inline-flex;
  align-items: baseline;
}

.tik4-link--within-body {
  /* tik4-link--within-body are links within a sentence / continuous text */
}

.tik4-link--muted {
  color: var(--t4-muted);
  &:hover,
  &:active {
    color: var(--t4-link);
  }
}
