
.tik4-author__wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tik4-author__thumb {
  border-radius: 9999em;
  margin-left: var(--t4-sp-2);
  overflow: hidden;
  height: 2em;
  width: 2em;
  flex: 0 0 2em;
}

.tik4-author__thumb__img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  display: block;
}

.tik4-author__name {
  font-size: var(--t4-txt-sm);
  color: var(--t4-muted);
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  white-space: nowrap;
}

.tik4-author__name__t {
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: 1.3;
}
.tik4-author__name:has(.tik4-author__name__bl) .tik4-author__name__t {
  /* font-weight: var(--t4-fw-b); */
  color: var(--t4-txt);
}

.tik4-author__name__bl {
  @extend %tik4-break-safe;
  line-height: 1.3;
  white-space: normal;
  font-size: var(--t4-txt-xs);
}

.tik4-live--author-top {
  & .tik4-author {
    /* flex: 1 1 0;
    min-width: 0; */
    min-width: 0;
    flex: 1 1 0;
    max-width: max-content;
    /* background: lightyellow; */
  }
}

.tik4-live--author-bottom-left {
  & .tik4-author {
    margin-top: var(--t4-sp-8);
    order: 90;
    flex: 1 1 1px;
    min-width: 0;
    margin-right: auto;
    padding-right: var(--t4-sp-8);
  }
  & .tik4-author__wrapper {
    justify-content: flex-start;
  }
  & .tik4-sharing {
    order: 100;
  }
  & .tik4-author__thumb {
    order: -1;
    margin-left: 0;
    margin-right: var(--t4-sp-2);
  }
  & .tik4-author__name {
    text-align: left;
  }
}

.tik4-live--author-bottom-right {
  & .tik4-author {
    margin-top: var(--t4-sp-8);
    order: 100;
    flex: 1 1 0;
    min-width: 0;
    margin-left: var(--t4-sp-8);
  }
  & .tik4-sharing.tik4-sharing {
    order: 90;
    margin-left: 0;
  }
  &.tik4-live--sharing-compact .tik4-sharing__items {
    right: auto;
    left: 0;
  }
  &.tik4-live--sharing-expanded .tik4-sharing__item__sh {
    right: auto;
    left: 0;
    transform: translateX(0);
  }
}

.tik4-live--author-off {
  & .tik4-author {
    display: none;
  }
}
