.tik4-we-video {
  max-width: var(--t4-we-max-width);
}

.tik4-we-video__container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  & iframe,
  & object,
  & embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
