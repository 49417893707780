.tik4-event {
  border-top: 1px solid var(--t4-ev-b);
  /* background: var(--t4-ev-bg); */
  position: relative;
  color: var(--t4-txt);
}

.tik4-event__body {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex: 1 0 0;
  min-width: 0;
  padding: var(--t4-ev-p-y) var(--t4-ev-p-x);
}

.tik4-content-blocks {
  flex: 0 0 100%;
  max-width: 100%;
}


.tik4-meta {
  display: flex;
  align-items: center;
  min-width: 0;
  padding-right: .5em;
  flex-basis: 70%;
  max-width: min-content;
}

.tik4-live--author-off,
.tik4-live--author-bottom-right,
.tik4-live--author-bottom-left {
  & .tik4-meta {
    flex-basis: 100%;
  }
}

.tik4-meta__ts__icons {
  display: inline-flex;
  align-items: center;
  margin-top: -.25em;
  &:has(.tik4-meta__ts__icon--sport) {
    & .tik4-meta__ts__icon--highlight {
      display: none;
    }
  }
}

.tik4-meta__ts__icon {
  display: inline-flex;
  margin-right: .2em;
}

.tik4-meta__ts__icon--sport {
  flex: 0 0 1.25em;
  width: 1.25em;
  height: 1.25em;
}

.tik4-meta__ts {
  display: flex;
  flex-direction: column;
  margin-right: .25em;
  max-width: 100%;
}

.tik4-meta__ts__time,
.tik4-meta__ts__date {
  white-space: nowrap;
}

.tik4-meta__ts__date {
  color: var(--t4-muted);
  overflow-x: hidden;
  overflow-x: clip;
  text-overflow: ellipsis;
  /* font-size: var(--t4-txt-sm); */
}

.tik4-meta__ts__time {
  font-weight: var(--t4-fw-b);
  font-size: var(--t4-txt-lg);
  display: flex;
  align-items: center;
}

.tik4-meta__ts__time__val {
  min-width: 0;
  overflow-x: hidden;
  overflow-x: clip;
  text-overflow: ellipsis;
  order: 10;
}

.tik4-meta__ts__time__title {
  min-width: 0;
  overflow-x: clip;
  text-overflow: ellipsis;
}



.tik4-event--summary {
  --t4-ev-bg: var(--t4-ev-s-bg);
  background: var(--t4-ev-s-bg);

  & .tik4-meta__ts__time__val,
  & .tik4-meta__ts__date,
  & .tik4-meta__ts__icon--pin,
  & .tik4-meta__ts__icon--highlight,
  & .tik4-meta__timeline__svg-icon.tik4-icon--star,
  & .tik4-meta__timeline__svg-icon.tik4-icon--pin {
    display: none;
  }
  & .tik4-meta__ts__time__title {
    order: 10;
  }

  &.tik4-event--highlight {
    & .tik4-meta__ts__icon--summary {
      border-color: transparent;
      background: var(--t4-ev-h-b-bg);
      color: #FFF;
    }
  }
}


.tik4-event--pin {
  --t4-ev-bg: var(--t4-ev-p-bg);
  background: var(--t4-ev-p-bg);
  & .tik4-meta__timeline__svg-icon.tik4-icon--star,
  & .tik4-meta__ts__time__val,
  & .tik4-meta__ts__date,
  & .tik4-meta__ts__icon--highlight {
    /* hide star icon when pinned */
    display: none;
  }
  &.tik4-event--highlight {
    & .tik4-meta__ts__icon--pin {
      border-color: transparent;
      background: var(--t4-ev-h-b-bg);
      color: #FFF;
    }
  }
}
.tik4-meta__ts__icon--pin,
.tik4-event--pin .tik4-meta__ts__icon--summary,
.tik4-event--highlight .tik4-meta__ts__icon--summary {
  padding: var(--t4-sp-1);
  box-sizing: content-box;
  border: 2px solid var(--t4-ev-b);
  border-radius: 999em;
}
.tik4-event--highlight .tik4-meta__ts__icon--summary {
  border: 0;
}
.tik4-live--invert-time .tik4-event--pin .tik4-meta__ts__time {
  background-color: transparent;
  font-size: var(--t4-txt-lg);
  color: inherit;
  padding-top: 0;
  padding-bottom: 0;
}

.tik4-event--highlight {
  --t4-ev-bg: var(--t4-ev-h-bg);
  background: var(--t4-ev-h-bg);
  &:before {
    content: '';
    width: .25em;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    background-color: var(--t4-ev-h-b-bg);
  }
}




.tik4-no-events {
  text-align: center;
  padding: 0 var(--t4-sp-7);
  margin: var(--t4-sp-8) 0;
}


/* deprecated: Height limited messages */

.tik4-event--hlim {
  /* max-height: var(--t4-ev-hlim);
  overflow: hidden; */
}

.tik4-event--hlim .tik4-event__body {
  mask-image: linear-gradient(180deg, rgba(0, 0, 0, 1) 40%, transparent 80%);
  max-height: var(--t4-ev-hlim);
  overflow: hidden;
}

.tik4-hlim {
  /* position: absolute; */
  /* padding-bottom: var(--t4-sp-6); */
  /* left: 0; */
  /* right: 0; */
  /* bottom: 0; */
  height: 3rem;
  margin-top: -3rem;
  text-align: center;
  z-index: 2;
  /* &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: var(--t4-ev-bg);
    mask-image: linear-gradient(0deg, rgba(0, 0, 0, 1) 20%, transparent 100%)
  } */
}

.tik4-hlim__link {
  position: relative;
  z-index: 1;
}
/* end deprecated: Height limited messages */

/* new: Height limited messages */

.tik4-hlimi {
  text-align: center;
  margin-top: var(--t4-sp-4);
  flex: 0 0 100%;
  max-width: 100%;
}

.tik4-event--hlimi-hidden {
  & .tik4-content-block--hlimi,
  & .tik4-content-block--comments,
  & .tik4-extension--below-comments,
  & .tik4-extension--below-content {
    display: none;
  }
  /* & .tik4-content-block--hlimi {
    height: 3rem;
    overflow: hidden;
    mask-image: linear-gradient(180deg, rgb(0, 0, 0) 0%, rgb(0, 0, 0, 0.1) 80%, transparent 100%);
    & + {
      & .tik4-content-block--hlimi {
        display: none;

      }
    }
  } */
}
.tik4-event--hlimi-visible {
  & .tik4-hlimi {
    display: none;
  }
}
/* end new: Height limited messages */
