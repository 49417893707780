.tik4-tws {
  /* overlap */
  --t4-tws-ol: 2em;
  /* item offset */
  --t4-tws-os: 2em;
  --t4-tws-i-sp: var(--t4-sp-1);
  min-width: 0;
  flex: 0 0 100%;
}

.tik4-tws__label {
  font-weight: var(--t4-fw-b);
  /* font-size: var(--t4-txt-lg); */
  /* padding: 0 var(--t4-sp-4); */
}

.tik4-tws__sl {
  overflow: hidden;
  &:not(.tik4-tws__sl--slide-active) {
    & .slick-list.slick-list {
      margin-right: 0;
    }
  }
  &.tik4-tws__sl--slide-active:not(.tik4-tws__sl--last-slides) {
    & .slick-list {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        width: calc(1 * var(--t4-tws-ol));
        background: var(--t4-tw-bg);
        mask-image: linear-gradient(-90deg, rgba(0, 0, 0, 1) 0%, transparent 100%);
        z-index: 1;
      }
    }
  }
  &:has(.slick-next.slick-disabled) .slick-list:before {
    display: none;
  }
  & ul.tik4-sl__d {
    display: none;
  }
}

.tik4-tws__sl .slick-prev {
  left: calc(-1 * var(--t4-sp-1));
}

.tik4-tws__sl .slick-arrow {
  transform: translate(0, calc(-50%))
}

.tik4-tws__sl .slick-arrow.slick-disabled {
  display: none;
}

.tik4-tws__sl .slick-list.slick-list {
  margin-right: var(--t4-tws-ol);
  overflow: visible;
  background: transparent;
  border: 0;
  border-radius: 0;
}

.tik4-tws__sl--last-slides .slick-list.slick-list {
  /* margin-right: 0; */
}

.tik4-tws__sl .slick-track {
  margin-left: calc(-1 * var(--t4-tws-i-sp));
  margin-right: calc(-1 * var(--t4-tws-i-sp));
  min-width: calc(100% + 2 * var(--t4-tws-i-sp))
}

.tik4-tws__sl .slick-slide.slick-slide {
  display: flex;
  align-items: flex-start;
  margin: 0 var(--t4-tws-i-sp);
}
.tik4-tws__i {
  margin-right: calc(-1* var(--t4-tws-os));
}

.tik4-tws__sl .slick-slide.slick-active:not(:first-child) .tik4-tws__i {
  margin-left: var(--t4-tws-os);
}

.tik4-tws__sl .slick-slide.slick-active .tik4-tws__i {
  margin-right: 0;
}

.tik4-tws__sl .slick-slide.slick-active ~ .slick-active .tik4-tws__i {
  margin-left: 0;
  margin-right: 0;
}

.tik4-tws__sl .slick-slide.slick-slide:last-child .tik4-tws__i {
  margin-right: calc(-1 * var(--t4-tws-ol));
}

.tik4-tws__sl .slick-slide > div {
  width: 100%;
  height: 100%;
}

/* trying to get equal widths for elements */

.tik4-tws__sl .slick-slide {
  --t4-tws-teil: calc(var(--t4-tws-os) / var(--t4-tws-stsc));
}

.tik4-tws__sl .slick-slide.slick-active:not(:first-child) .tik4-tws__i {
  --t4-tws-sla-ml: calc(var(--t4-tws-os) - var(--t4-tws-teil) * var(--t4-tws-sla-i));
  margin-left: var(--t4-tws-sla-ml);
  margin-right: calc(var(--t4-tws-os) * (-1 + (var(--t4-tws-sla-i) + 1) / var(--t4-tws-stsc)));
}

/* reset everything if not slided */
.tik4-tws__sl .slick-slide.slick-active:first-child ~ .slick-active .tik4-tws__i {
  /* background: orange !important; */
  margin-right: 0 !important;
  margin-left: 0 !important;
}

/* first active element */
.tik4-tws__sl .slick-slide.slick-active:not(:first-child) .tik4-tws__i {
  /* background: purple; */
  --t4-tws-sla-i: 0;
  /* margin-left: var(--t4-tws-os); */
  /* margin-right: calc(var(--t4-tws-os) * (-1 + 1 / var(--t4-tws-stsc))); */
}

/* 2nd (second after first active element */
.tik4-tws__sl .slick-slide.slick-active:not(:first-child) ~ .slick-active .tik4-tws__i {
  /* background: blue; */
  --t4-tws-sla-i: 1;
  /* margin-left: calc(var(--t4-tws-os) - var(--t4-tws-teil)); */
  /* margin-right: calc(var(--t4-tws-os) * (-1 + 2 / var(--t4-tws-stsc))); */
}
/* 3rd (third element after active) */
.tik4-tws__sl .slick-slide.slick-active:not(:first-child) ~ .slick-active ~ .slick-active .tik4-tws__i {
  /* background: green; */
  --t4-tws-sla-i: 2;
  /* margin-left: calc(var(--t4-tws-os) - var(--t4-tws-teil) * 2); */
  /* margin-right: calc(var(--t4-tws-os) * (-1 + 3 / var(--t4-tws-stsc))); */
}
/* 4th (third fourth after active) */
.tik4-tws__sl .slick-slide.slick-active:not(:first-child) ~ .slick-active ~ .slick-active ~ .slick-active .tik4-tws__i {
  /* background: pink; */
  --t4-tws-sla-i: 3;
  /* margin-left: calc(var(--t4-tws-os) - var(--t4-tws-teil) * 3); */
  /* margin-right: calc(var(--t4-tws-os) * (-1 + 4 / var(--t4-tws-stsc))); */
}
/* 4th (third fourth after active) */
.tik4-tws__sl .slick-slide.slick-active:not(:first-child) ~ .slick-active ~ .slick-active ~ .slick-active ~ .slick-active .tik4-tws__i {
  /* background: yellow; */
  --t4-tws-sla-i: 4;
  /* margin-left: calc(var(--t4-tws-os) - var(--t4-tws-teil) * 4); */
  /* margin-right: calc(var(--t4-tws-os) * (-1 + 5 / var(--t4-tws-stsc))); */
}
/* end of equal widhts */

/* last item is not equal width.. unfortunately after updating the react-slick version it didnt work smoothly anymore */
.tik4-tws__sl.tik4-tws__sl--slide-active .slick-slide:last-child .tik4-tws__i {
  margin-right: calc(-1 * ( var(--t4-tws-ol) + 2 * var(--t4-tws-i-sp) ));
}

/* item (i) */
.tik4-tws__i {
  height: 100%;
  position: relative;

    &:hover {
      cursor: pointer;
      & .tik4-tws__ic {
        filter: brightness(0.985);
        color: var(--t4-tw-link);
      }
    }
}
a.tik4-tws__i.tik4-tws__i {
  display: block;
  color: var(--t4-tw-txt);
  text-decoration: none;
}

/* .tik4-tws__sl .slick-slide:nth-child(odd) .tik4-tws__i {
  background: red;
} */

/* (t)ime(l)ine */
.tik4-tws__tl {
  align-items: center;
  padding-left: calc(var(--t4-sp-3) + 1px);
  position: relative;
  margin-bottom: var(--t4-sp-1);
}

.tik4-tws__tl__c {
  display: flex;
  align-items: center;
  background: var(--t4-tw-bg);
  position: relative;
  gap: 0.25em;
  margin-left: calc(-1 * var(--t4-sp-2));
  padding-left: var(--t4-sp-2);
  padding-right: var(--t4-sp-2);
}


.tik4-tw--tl:not(.tik4-tw--tl--t) {
  & .tik4-tws__tl__c {
    margin-left: 0;
    padding-left: 0;
    padding-right: 0;
    outline: 1px solid var(--t4-tw-bg);
  }
}

/* .tik4-tws__dt--tl,
.tik4-tws__icon--tl {
  margin-left: calc(-1 * var(--t4-sp-2));
  padding-left: var(--t4-sp-2);
  padding-right: var(--t4-sp-2);
} */

.tik4-tws__icon--tl {
  /* display: none; */
  line-height: 1;
  /* & ~ .tik4-tws__dt--tl {
    padding-left: 0.125em;
  } */
  & .tik4-tws__icon__i.tik4-tws__icon__i {
    top: -0.075em;
  }
}

.tik4-tws__tl__d {
  height: .9em;
  flex: 0 0 .5em;
  position: relative;
  display: flex;
  align-items: center;
  /* margin-left: calc(-1 * var(--t4-sp-2));
  margin-right: calc(-1 * var(--t4-sp-2)); */
  &:before {
    content: "";
    display: block;
    width: .5em;
    height: .5em;
    /* border-radius: var(--t4-rounded-sm); */
    border-radius: .99em;
    background: var(--t4-tw-tl-c);
    /* outline: 1px solid var(--t4-bg); */
  }
}

.tik4-tws__tl__b {
  position: absolute;
  left: 0;
  right: calc(var(--t4-tws-i-sp) * -2);
  top: calc(50% - 0.5px);
  background: var(--t4-tw-tl-c);
  height: 1px;
}

.tik4-tws__sl .slick-slide:first-child .tik4-tws__tl__b {
  left: var(--t4-sp-3);
}

.tik4-tws__sl .slick-slide:last-child .tik4-tws__tl__b {
  right: 0;
}

.tik4-tws__icon {
  /* margin-right: 0.125em; */
}

.tik4-tws__icon__i.tik4-tws__icon__i {
  top: -0.1em;
}

.tik4-tws__icon ~ .tik4-tws__tl__d {
  display: none;
}

/* item (i) container (c) */
.tik4-tws__ic {
  background: var(--t4-tw-sfc);
  padding: var(--t4-sp-3) var(--t4-sp-3);
  box-sizing: border-box;
  border-radius: var(--t4-rounded);
  border: 1px solid transparent;
  transition: border var(--t4-duration), color var(--t4-duration);
}

/* item header (i__h) */
.tik4-tws__i__h {
  display: flex;
  align-items: center;
  /* min-height: var(--t4-sp-10); */
  margin-bottom: var(--t4-sp-0-5);
}

.tik4-tws__i__hc {
  min-width: 0;
  display: flex;
  align-items: center;
  gap: 0.25em;
}



.tik4-tws__icon--hi {
  display: flex;
}

.tik4-tw--invert-time {
  & .tik4-tws__i__hc,
  &.tik4-tw--tl--t   .tik4-tws__tl__c,
  & .tik4-twl__h__c {
    background: var(--t4-tw-bg--i);
    color: var(--t4-tw-txt--i);
    border-radius: var(--t4-rounded);
    padding: var(--t4-sp-0-5) var(--t4-sp-2);
    font-weight: var(--t4-fw-b);
  }

  & .tik4-tws__dt,
  & .tik4-twl__dt {
    color: inherit;
  }
  & .tik4-twl__h__c {
    margin-bottom: var(--t4-sp-1);;
  }
}

/* datetime */

.tik4-tws__dt {
  /* align-items: center; */
  min-width: 0;
  line-height: 1.3;
  color: var(--t4-tw-muted);
  font-size: var(--t4-txt-sm);
}

.tik4-tws__dt__d {
  white-space: nowrap;
  overflow-x: hidden;
  overflow-x: clip;
  text-overflow: ellipsis;
}

.tik4-tws__dt__div {
  margin-right: .275em;
}

.tik4-tws__dt__t {
  white-space: nowrap;
}





.tik4-tws__c {
  @extend %tik4-break-safe;
  font-weight: var(--t4-fw-b);
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  height: calc(1em * var(--t4-txt-lh) * 3);
}
