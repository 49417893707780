.tik4-media-item {

  &:not(:first-child) {
    margin-top: var(--t4-sp-1);
  }

  /* &.tik4-media-item--image {

  } */

  & .tik4-media-item--video {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
    & > div {
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
}


.tik4-media-image {

}

.tik4-media-image__img-wrapper {
  --t4-media-img-aspect-ratio: var(--t4-media-img-width) / var(--t4-media-img-height);
  position: relative;
  overflow: hidden;
  max-height: var(--t4-media-max-h);

  aspect-ratio: var(--t4-media-img-aspect-ratio);
  /* max-width is needed for safari, otherwise it will show gray background
  it sets max-width to the smallest of: image-width OR aspect-ratio * max-height OR 100% */
  max-width: min(calc(var(--t4-media-img-width)* 1px), calc( var(--t4-media-img-aspect-ratio) * var(--t4-media-max-h)), 100%);
}

.tik4-media-image__img-wrapper--slideshow-trigger {
  cursor: zoom-in;
}

.tik4-media-image__img {
  position: relative;
  z-index: 1;
  object-fit: contain;
  object-position: top left;
  max-width: 100%;
  max-height: var(--t4-media-max-h);
  height: auto;
  display: block;
  background: var(--t4-sfc-bg) no-repeat center;
  background-size: 20%;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cg transform='translate(1 1)' stroke='%23FFF' stroke-width='2' fill='none' fill-rule='evenodd' opacity='.5' stroke-linecap='round' stroke-linejoin='round'%3E%3Crect width='18' height='18' rx='2'/%3E%3Ccircle cx='5.5' cy='5.5' r='1.5'/%3E%3Cpath d='m18 12-5-5L2 18'/%3E%3C/g%3E%3C/svg%3E");
}

.tik4-media-item-error {
  width: 100%;
  aspect-ratio: 2 / 1;

  color: var(--t4-muted);
  background: var(--t4-bg);
  font-weight: var(--t4-fw-b);
  text-align: center;
}

.tik4-media-item-error__container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: var(--t4-sfc-bg);
  padding: 0 2em;
}

.tik4-media-item-error__icon {
  flex: 0 0 1.5em;
  height: 5em;
  width: 5em;
  margin-bottom: var(--t4-sp-1);
}


/* warning */
.tik4-media-image--has-warning .tik4-media-image__img,
.tik4-media-video--has-warning .tik4-media-video__video {
 filter: blur(25px);
}

.tik4-media-image__warning {
  position: absolute;
  z-index: 2;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  color: var(--t4-txt--d);
  display: none;
}

.tik4-media-image--has-warning .tik4-media-image__warning,
.tik4-media-video--has-warning .tik4-media-image__warning {
  /* css visibility handling so it works for amp as well */
  display: block;
}

.tik4-media-image__warning__overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  opacity: 0.25;
  background: var(--t4-bg--d);
  /* &:after {
    backdrop-filter: blur(20px);
    content: '';
    opacity: 0.25;
    width: 100%;
    height: 100%;
    display: block;

  } */
}

.tik4-media-image__warning__content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: var(--t4-sp-3);
  font-size: var(--t4-txt-sm);
}

.tik4-media-image__warning__button {
  margin-bottom: var(--t4-sp-2);
}

/* .tik4-media-image__blur {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  filter: blur(2em);
  transform: scale(1.2);
  opacity: 0.6;
} */


.tik4-media-video__video-wrapper {
  position: relative;
  /* max-height: 20em; */
  overflow: hidden;
  & video {
    /* max-height: 20em; */
  }
  & iframe {
    border: none;
  }
}

.tik4-media-body {
  color: var(--t4-muted);
  margin-top: .4em;
  margin-bottom: 1em;
  font-style: italic;
  font-size: var(--t4-txt-sm);
}

.tik4-media-item:last-child {
  & .tik4-media-body {
    margin-bottom: 0;
  }
}


.tik4-media-body__title {
  @extend %tik4-break-safe;
}

.tik4-media-body__credit {
  @extend %tik4-break-safe;
}


.media-drop-area {
  cursor: default;
}
