
.tik4-event__timeline {
  display: none;
}
.tik4-meta__timeline {
  display: none;
}

.tik4-live--timeline {
  /* All styles, which differ from noraml tik4-live layout */
  --t4-ev-p-l: calc(var(--t4-tl-ml) + var(--t4-tl-mr) + var(--t4-tl-w));
  & .tik4-event__body {
    padding-left: var(--t4-ev-p-l);
  }
  & .tik4-event {
    --t4-tl-dot-border: var(--t4-ev-bg);
    /* --t4-ev-p-y: var(--t4-sp-8); */
    border-top: 0;
    position: relative;
  }
  &:not(.tik4-live--cards) .tik4-event {
    &:after {
      content: "";
      border-top: 1px solid var(--t4-ev-b);
      height:0;
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      left: calc(var(--t4-tl-ml) + var(--t4-tl-w));
    }
    &.tik4-event--highlight,
    &.tik4-event--pin {
      &:after,
      & + .tik4-event:after {
        left: 0;
      }
    }
  }

  & .tik4-chapter {
    border-top: 0;
  }
  & .tik4-event__timeline {
    display: block;
  }
  & .tik4-meta__timeline {
    display: flex;
  }
  & .tik4-event--highlight:before {
    display: none;
  }
  & .tik4-meta__ts__icon--highlight {
    display: none;
  }
  & .tik4-meta__ts__icon--summary {
    display: none;
  }
  & .tik4-meta__ts__icon--sport {
    display: none;
  }
  & .tik4-event--pin {
    & .tik4-meta__ts {
      /* hide complete meta (date + pin) when pinned */
      display: none;
    }
  }
  /* set margin top top 0 if author is displayed bottom or off */
  &[class*="tik4-live--author-bottom"],
  &.tik4-live--author-off {
    & .tik4-event--pin {
      & .tik4-meta {
        margin-right: 0;
        padding-right: 0;
        align-self: flex-start;
      }
      & .tik4-author + .tik4-content-block {
        margin-top: 0;
      }
    }
  }

  & .tik4-extension--event-top,
  & .tik4-extension--event-bottom {
    padding-left: var(--t4-ev-p-l);
  }
}

/* these styles could also be scoped by '.tik4-live--timeline' but its not necessary so lets save some bytes ;)  */
.tik4-event__timeline {
  position: absolute;
  left: var(--t4-tl-ml);
  top: 0;
  bottom: 0;
  &:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    border-right: var(--t4-tl-w) var(--t4-tl-style);
    opacity: 0.2;
  }
}

.tik4-live--cards .tik4-event:first-child,
.tik4-live--cards .tik4-chapter + .tik4-event {
  & .tik4-event__timeline {
    top: calc(var(--t4-ev-p-y) + var(--t4-sp-5));
  }
}

.tik4-meta__timeline {
  z-index: 1;
  justify-content: center;
  margin-left: calc(-1*var(--t4-tl-mr));
  width: 0;
  margin-right: var(--t4-tl-mr);
  position: relative;
  left: calc(-1*var(--t4-tl-w)/2);
}

.tik4-meta__timeline__si {
  width: 1.5em;
  height: 1.5em;
  flex: 0 0 1.5em;
  background: var(--t4-ev-bg);
  border-radius: 99em;
  & ~ .tik4-meta__timeline__dot,
  & ~ .tik4-meta__timeline__svg-icon-container {
    display: none;
  }
}
.tik4-meta__timeline__si__i {
  width: 100%;
  height: 100%;
  display: block;
}

.tik4-meta__timeline__dot {
  width: var(--t4-tl-dot-size);
  flex: 0 0 var(--t4-tl-dot-size);
  height: var(--t4-tl-dot-size);
  border-radius: 99em;
  background: var(--t4-primary);
  border: 2px solid var(--t4-tl-dot-border);
}

.tik4-meta__timeline__svg-icon-container {
  background: var(--t4-ev-bg);
  border: 2px solid var(--t4-ev-b);
  flex: 0 0 var(--t4-tl-ic-size);
  width: var(--t4-tl-ic-size);
  height: var(--t4-tl-ic-size);
  border-radius: 99em;
  display: flex;
  justify-content: center;
  align-items: center;
  & + .tik4-meta__timeline__dot {
    display: none;
  }
}
.tik4-meta__timeline__svg-icon {
  top: 0;
}



.tik4-event--highlight {
  & .tik4-event__timeline {
    &:after {
      border-right-color: var(--t4-ev-h-b-bg);
      opacity: 1;
    }
  }
  & .tik4-meta__timeline__svg-icon-container {
    background: var(--t4-ev-h-b-bg);
    color: #FFF;
    border-color: var(--t4-ev-bg);
  }
}
