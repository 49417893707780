.tik4 {

  --t4-min-width: 0;

  /* BASE */
  --t4-rounded-sm: calc(var(--t4-sc) * .125rem);
  --t4-rounded: calc(var(--t4-sc) * .25rem);
  --t4-rounded-lg: calc(var(--t4-sc) * .5rem);

  --t4-duration: .1s;
  --t4-duration-slow: 300ms;

  /* SPACER */
  --t4-sp-0-5: calc(var(--t4-sc) * .125rem);
  --t4-sp-1: calc(var(--t4-sc) * .25rem);
  --t4-sp-1-5: calc(var(--t4-sc) * .375rem);
  --t4-sp-2: calc(var(--t4-sc) * .5rem);
  --t4-sp-2-5: calc(var(--t4-sc) * .625rem);
  --t4-sp-3: calc(var(--t4-sc) * .75rem);
  --t4-sp-4: calc(var(--t4-sc) * 1rem);
  --t4-sp-5: calc(var(--t4-sc) * 1.25rem);
  --t4-sp-6: calc(var(--t4-sc) * 1.5rem);
  --t4-sp-7: calc(var(--t4-sc) * 1.75rem);
  --t4-sp-8: calc(var(--t4-sc) * 2rem);
  --t4-sp-10: calc(var(--t4-sc) * 2.5rem);
  --t4-sp-12: calc(var(--t4-sc) * 3rem);


  /* TYPO */
  --t4-sc: 1;
  --t4-txt-xs: calc(var(--t4-sc) * .75rem);
  --t4-txt-sm: calc(var(--t4-sc) * .875rem);
  --t4-txt-base: calc(var(--t4-sc) * 1rem);
  --t4-txt-lg: calc(var(--t4-sc) * 1.2rem);
  --t4-txt-xl: calc(var(--t4-sc) * 1.44rem);

  --t4-fw-b: 700;

  /* LINE-HEIGHTS */
  --t4-txt-lh: 1.5;
  --t4-txt-lg-lh: 1.4;
  --t4-txt-xl-lh: 1.3;


  /* COLORS */
  /* All colors need a --l (light), --d (dark) variant. Every color needs to be added to tik4-color-scheme.css file within setColorSCheme mixin!!! */
  --t4-bg--l: #FFFFFF;
  --t4-bg--d: #242322;

  --t4-primary--l: #E01A4F;
  --t4-primary--d: #E72D5F;

  --t4-secondary--l: #0085CC;
  --t4-secondary--d: #00A2FA;

  --t4-txt--l: #1C1C1B;
  --t4-txt--d: #FAF9F7;

  --t4-muted--l: #706F6D;
  --t4-muted--d: #B8B7B6;

  /* --t4-txt-quote--l: #706F6D;
  --t4-txt-quote--d: #D0CFCE; */

  --t4-link--l: var(--t4-secondary--l);
  --t4-link--d: var(--t4-secondary--d);

  --t4-warn--l: #8F6D00;
  --t4-warn--d: #FED967;

  --t4-error--l: #e01e00;
  --t4-error--d: #ff3213;


  /* --t4-bg-error--l: #ffe6e2;
  --t4-bg-error--d: #FF6047; */


  /* BUTTON, LINKS */
  --t4-hover-saturate: saturate(70%);
  --t4-active-saturate: saturate(50%);


  /* EVENT */
  --t4-ev-h-bg--l: #F5F3F0;
  --t4-ev-h-bg--d: #333231;

  /* event highlight */
  --t4-ev-h-b-bg--l: var(--t4-primary--l);
  --t4-ev-h-b-bg--d: var(--t4-primary--d);

  /* event pinned */
  --t4-ev-p-bg--l: #F5F3F0;
  --t4-ev-p-bg--d: #333231;

  /* event-summary */
  --t4-ev-s-bg--l: #F5F3F0;
  --t4-ev-s-bg--d: #333231;

  /* event padding */
  --t4-ev-p-x: var(--t4-sp-4);
  --t4-ev-p-y: var(--t4-sp-6);

  /* event border */
  --t4-ev-b--l: rgba(0,0,0,.12);
  --t4-ev-b--d: rgba(255,255,255,0.12);

  /* event limit post max-height */
  --t4-ev-hlim: calc(var(--t4-sc) * 20rem);

  /* event timeline */
  /* --t4-tl-bg--l: rgba(112,111,109, 0.25);
  --t4-tl-bg--d: rgba(112,111,109, 0.25); */

  --t4-tl-dot-bg--d: #D0CFCE;
  /* --t4-tl-dot-bg--l: #B8B7B6;
   */
  /* --t4-tl-dot-bg--l: var(--t4-primary--l);
  --t4-tl-dot-bg--d: var(--t4-primary--d) ; */

  /* --t4-tl-ic-b--l: rgba(112,111,109, 0.20);
  --t4-tl-ic-b--d: rgba(112,111,109, 0.40); */

  --t4-tl-w: 1px;
  --t4-tl-style: solid;
  --t4-tl-ml: var(--t4-sp-4);
  --t4-tl-mr: var(--t4-sp-5);
  /* @media screen and (min-width: 40em) {
    --t4-tl-ml: var(--t4-sp-6);
    --t4-tl-mr: var(--t4-sp-10);
  } */
  --t4-tl-dot-size: 1em;
  --t4-tl-ic-size: 1.765em;

  /* MILESTONE */
  --t4-ms-pt: var(--t4-sp-12);
  --t4-ms-pb: var(--t4-sp-6);

  /* CARDS */
  --t4-ca-m: var(--t4-sp-5);
  /* --t4-ca-sh--l: 0 .125em .625em .125em rgba(94,97,99,0.10), 0 0.0625em .1875em 0 rgba(94,97,99,0.15);
  --t4-ca-sh--d: 0 .125em .625em .125em rgba(0,0,0,0.11), 0 0.0625em .1875em 0 rgba(0,0,0,0.20); */
  --t4-ca-sh--l: 0 .13em .63em .13em rgba(94,97,99,.10), 0 0.06em .19em 0 rgba(94,97,99,.15);
  --t4-ca-sh--d: 0 .13em .63em .13em rgba(0,0,0,.11), 0 0.06em .19em 0 rgba(0,0,0,.20);


  /* FORM */
  --t4-ff-radius: var(--t4-rounded);
  --t4-ff-lh: 1.5;

  --t4-ff-bg--l: #FFF;
  --t4-ff-bg--d: rgba(255,255,255, 0.07);


  --t4-ff-b--l: rgba(0,0,0,.2);
  --t4-ff-b--d: rgba(255,255,255, 0.24);

  /* f: focus */
  --t4-ff-b-f--l: rgba(0,0,0,.3);
  --t4-ff-b-f--d: rgba(255,255,255, 0.6);

  --t4-ff-sh: inset 0 0.0625em .25em 0 rgba(0,0,0,0.08);
  --t4-ff-sh-focus: inset 0 0.0625em .25em 0 rgba(0,0,0,0.25), 0 0.0625em .3125em rgba(0,0,0,0.25);

  --t4-modal-content-margin: var(--t4-sp-12);


  /* WEB EMBED */
  --t4-we-max-width: 50em;

  /* --t4-we-b--l: rgba(0,0,0,.12);
  --t4-we-b--d: rgba(255,255,255,.12); */

  /* h:hover */
  /* --t4-we-b-h--l: rgba(0,0,0,0.5);
  --t4-we-b-h--d: rgba(255,255,255,.2); */

  /* --t4-we-bg-hover--l: rgba(0,0,0, .04);
  --t4-we-bg-hover--d: rgba(255,255,255, .04); */

  /* --t4-media-image-bg--l: radial-gradient(#fff, #E5E4E3);
  --t4-media-image-bg--d: radial-gradient(rgba(0,0,0,0.1), rgba(0,0,0,0.2)); */
  --t4-media-max-h: max(28em, 80vh);

  /* to = toggle, dis = disabled, en = enabled */
  /* --t4-we-to-dis--l: rgba(0,0,0,0.5);
  --t4-we-to-dis--d: rgba(255,255,255,0.5); */
  /* --t4-we-to-en--l: var(--t4-secondary);
  --t4-we-to-en--d: var(--t4-secondary); */

  /* surface */
  --t4-sfc-bg--l: rgba(112,111,109, 0.15);
  --t4-sfc-bg--d: rgba(255,255,255, 0.07);

  --t4-sfc-b--l: rgba(112,111,109, 0.20);
  --t4-sfc-b--d: rgba(255,255,255, 0.14);


  /* MODAL */
  /* --t4-modal-bg--l: var(--t4-bg--l);
  --t4-modal-bg--d: var(--t4-bg--d);; */

  /* COMMENTS */
  --t4-co-txt: var(--t4-txt-base);

  --t4-co-radius: var(--t4-rounded);

  /* Tag border radius */
  --t4-tag-rounded: 99em;

}



/* Problem. bei grid layout wird event ja auch wieder kleiner ausgegeben */
.tik4-live:not(.tik4-live--masonry) {
  &.tik4-live--sm {
    --t4-ev-p-x: var(--t4-sp-6);
    --t4-ev-p-y: var(--t4-sp-7);

    /* --t4-tl-ml: var(--t4-sp-4); */
    --t4-tl-mr: var(--t4-sp-8);
  }
}
