@define-mixin setTeaserWidgetColorScheme $scheme, $schemeInvert {
  /* Please add any new color from tik4-teaser-widget-colors.css here */
  --t4-tw-bg: var(--t4-tw-bg--$(scheme));
  --t4-tw-bg--i: var(--t4-tw-bg--$(schemeInvert));
  --t4-tw-txt: var(--t4-tw-txt--$(scheme));
  --t4-tw-txt--i: var(--t4-tw-txt--$(schemeInvert));
  --t4-tw-muted: var(--t4-tw-muted--$(scheme));
  --t4-tw-link: var(--t4-tw-link--$(scheme));
  --t4-tw-sfc: var(--t4-tw-sfc--$(scheme));
  --t4-tw-lic: var(--t4-tw-lic--$(scheme));
}


/* LIGHT COLORS */
.tik4-tw.tik4--on-light,
.tik4-tw.tik4--on-auto {
  @mixin setTeaserWidgetColorScheme l, d;
}

/* DARK COLORS */
.tik4-tw.tik4--on-dark  {
  @mixin setTeaserWidgetColorScheme d, l;
}


@media (prefers-color-scheme: dark) {
  .tik4-tw.tik4--on-auto  {
    @mixin setTeaserWidgetColorScheme d, l;
  }
}
