
.tik4-event:first-child .tik4-extension ~ .tik4-event__timeline, .tik4-chapter + .tik4-event .tik4-extension ~ .tik4-event__timeline {
  top: 0;
}

.tik4-extension--event-top {
  padding: var(--t4-ev-p-y) var(--t4-ev-p-x) 0;
}

.tik4-extension--above-content,
.tik4-extension--below-content,
.tik4-extension--below-comments {
  padding: 0;
}

.tik4-extension--event-bottom {
  padding: 0 var(--t4-ev-p-x) var(--t4-ev-p-y);
}


.tik4-extension--between-event {
  overflow: hidden;
  border-top: 1px solid var(--t4-ev-b);
  padding: var(--t4-ev-p-y) 0;
}

.tik4-live--cards .tik4-extension--between-event {
  padding: 0;
  border-top: 0;
}

.tik4-live--cards .tik4-extension--between-event:not(:first-child) {
  margin-top: var(--t4-ca-m);
}
