/* WARNING: @extend does not work with nested selectors!! so make sure, your selectors are not nested, when using @extend */

/* %tik4-reset {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
} */

@define-mixin tik4-button-reset {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}

%tik4-button-reset {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
}



%tik4-break-safe {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.tik4-break-safe {
  @extend %tik4-break-safe;
}


%tik4-link-style {
  color: var(--t4-link);
  &:hover {
    /* color: var(--t4-txt); */
    color: inherit;
  }
  &:active {
    color: var(--t4-link);
  }
}
