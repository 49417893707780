.tik4-we--facebook {
  & .fb_iframe_widget {
    display: block;
    overflow-x: auto;
    &.fb-video {
      max-width: var(--t4-we-max-width);
    }
    /* targeting facebooks photo widget which doesnt have a background causing issues when in dark mode.. photo caption is not readable */
    &.fb_iframe_widget[data-href*='photo.php'] iframe {
      background: #FFF;
      border-radius: 3px;
    }
  }
}

/* TODO:Timo this is a quickfix to try to prevent fb post to scroll horizontally */
@media screen and (max-width: 380px) {
  .tik4-we--facebook .fb_iframe_widget {
    overflow-x: hidden;
  }
  .tik4-we-facebook .fb_iframe_widget span {
    transform-origin: 0 0;
    transform: scale(0.85);
  }
}
