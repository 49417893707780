.tik4-twt__tc {
  font-size: var(--t4-txt-lg);
  font-weight: var(--t4-fw-b);
  line-height: var(--t4-txt-lg-lh);
  color: var(--t4-tw-txt);
  cursor: pointer;
  display: flex;
  align-items: baseline;
  text-decoration: none;
  transition: color var(--t4-duration), filter var(--t4-duration);
}

a.tik4-twt__tc {
  color: var(--t4-tw-txt);
  &.tik4-twt__tc--l {
    &:hover {
      color: var(--t4-tw-link);
      filter: var(--t4-hover-saturate);
    }
    &:active {
      color: var(--t4-tw-link);
      filter: var(--t4-active-saturate);
    }
  }
}
