.tik4-hs {
  /* padding: 0 var(--t4-ev-p-x) 0; */
  margin-bottom: var(--t4-sp-8);
  &:not(:first-child) {
    margin-top: var(--t4-sp-8);
  }
}

.tik4-hs__label {
  font-size: var(--t4-txt-sm);
  color: var(--t4-muted);
}

.tik4-hs__list {
  padding-left: 1em;
}

.tik4-hs__item {
  margin-top: var(--t4-sp-2);
  position: relative;
}

.tik4-hs__item:before {
  content: '';
  position: absolute;
  top: auto;
  left: -.825em;
  transform: translateY(.6em);
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: currentColor;
}

h3.tik4-hs__title {
  font-weight: var(--t4-fw-b);
  font-size: var(--t4-txt-base);
  margin-top: 0;
  margin-bottom: 0;
  cursor: pointer;
  display: flex;
  align-items: baseline;
  &:hover {
    color: var(--t4-secondary);
    filter: var(--t4-hover-saturate);
  }
  &:active {
    color: var(--t4-secondary);
    filter: var(--t4-active-saturate);
  }
}


.tik4-hs__title__content {
  --t4-hs-c-l: 2;
  min-width: 0;
  @extend %tik4-break-safe;

  display: -webkit-box;
  -webkit-line-clamp: var(--t4-hs-c-l);
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: var(--t4-txt-lh);
  max-height: calc(1em * var(--t4-txt-lh) * var(--t4-hs-c-l));
}
