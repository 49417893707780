.tik4-we-disabled {
  background-color: var(--t4-sfc-bg);
  border: 1px solid var(--t4-sfc-b);
  border-radius: var(--t4-rounded);
  padding: var(--t4-sp-6) var(--t4-sp-4);
  color: var(--t4-muted);
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.tik4-we-disabled__icon {
  width: 1.5em;
  height: 1.5em;
}

.tik4-we-disabled__headline {
  margin-top: var(--t4-sp-2);
}

.tik4-we-disabled__text {
  margin-top: var(--t4-sp-2);
  font-size: var(--t4-txt-sm);
}

.tik4-we-disabled__info-icon {
  width: .9em;
  height: .9em;
  position: relative;
  top: .075em;
  flex: 0 0 9em;
  margin-right: .425em;
  vertical-align: baseline;
  display: inline;
}
