.tik4-live--masonry {
  /* Dont use 'rem'! it will break the height calculation */
  --t4-masonry-gap-col: 14px;
  --t4-masonry-gap-row: 2px;
  /* Hiding events while calculating size */
  & .tik4-event:not([style*="grid-row-end:"]),
  & .tik4-chapter:not([style*="grid-row-end:"]),
  & .tik4-extension--between-event:not([style*="grid-row-end:"]) {
    visibility: hidden;
  }
  & .tik4-event-list {
    grid-gap: var(--t4-masonry-gap-row) var(--t4-masonry-gap-col);
    grid-template-columns: repeat(auto-fill, minmax(22.5em,1fr));
    grid-auto-rows: 0;
    /* if not in media query, content will overflow screens/content areas narrower than 360px */
    @media screen and (min-width: 28.75em) {
      display: -ms-grid;
      display: grid;
    }
  }
  & .tik4-event.tik4-event {
    margin-top: 0;
    /* border-top: 0; */
  }
  &.tik4-live--cards {
    /* border-top: 0; */
    --t4-masonry-gap-row: var(--t4-masonry-gap-col);
    & .tik4-event.tik4-event:not(first-child) {
      margin-top: var(--t4-ca-m);
      /* if not in media query, content will overflow screens/content areas narrower than 360px */
      @media screen and (min-width: 28.75em) {
        margin-top: 0;
      }
    }
    & .tik4-extension--between-event.tik4-extension--between-event:not(first-child) {
      margin-top: var(--t4-ca-m);
      /* if not in media query, content will overflow screens/content areas narrower than 360px */
      @media screen and (min-width: 28.75em) {
        margin-top: 0;
      }
    }
  }
  & .tik4-chapter {
    padding:
      calc(var(--t4-ms-pt) - var(--t4-masonry-gap-row))
      calc(var(--t4-ev-p-x) - var(--t4-masonry-gap-row))
      calc(var(--t4-ms-pb) - var(--t4-masonry-gap-row));

    &:first-child {
      grid-column: 1 / -1;
    }
  }
}
