.tik4-by {
  margin-top: var(--t4-sp-12);
}

a.tik4-by__link.tik4-by__link {
  display: flex;
  align-items: baseline;
  justify-content: center;
  transition: all var(--t4-duration);
  color: var(--t4-muted);
  opacity: 0.5;
  text-decoration: none;
  font-size: var(--t4-txt-xs);
  border: 0;
  &:hover {
    opacity: 1;
    & .tik4-by__logo {
      color: #343434;
    }
  }
}

.tik4-by__tickaroo {
  display: none;
}

.tik4-by__logo {
  transition: all var(--t4-duration);
  margin-right: var(--t4-sp-1);
  width: 4.27em;
  height: 0.93em;
}
