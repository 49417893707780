/* SE: SportEvent */

.tik4-se {
  padding-left: var(--t4-sp-12);
}

.tik4-se__w {
  border-top: 1px solid var(--t4-sfc-b);
  border-bottom: 1px solid var(--t4-sfc-b);
  padding: var(--t4-sp-6) 0;
}

.tik4-content-block--sport-event {
  & + .tik4-content-block--sport-event .tik4-se__w {
    border-top-width: 0;
    padding-top: 0;
  }
}

/* event sport */
.tik4-event--sport .tik4-content-block--sport-event.tik4-content-block--position-1 {
  & .tik4-se {
    padding-left: 0;
  }
  & .tik4-se__w {
    border-top-width: 0;
    border-bottom: 0;
    padding-top: 0;
    padding-bottom: 0;
  }
  & + .tik4-content-block--sport-event .tik4-se__w {
    border-top-width: 1px;
    padding-top: var(--t4-sp-6);
  }
}

.tik4-se__m {
  position: relative;
}

/* m: meta */
/* i: icon */
.tik4-se__m__i {
  display: none;
  display: flex;
  z-index: 1;
  justify-content: center;
  align-items: center;
  /* width: 0; */
  position: absolute;
  left: calc(-1 * var(--t4-sp-12));
  /* left: calc(-1*var(--t4-tl-w)/2); */
}

.tik4-se__m--w-pt .tik4-se__m__i {
  top: -.125em;
}
.tik4-se__m--w-top .tik4-se__m__i {
  top: 0;
}

.tik4-se__m--w-pt.tik4-se__m--w-top .tik4-se__m__i {
  top: -.25em;
  bottom: 0;
}

/* c: container */
.tik4-se__m__i__c {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--t4-tl-ic-size);
  height: var(--t4-tl-ic-size);
  flex: 0 0 var(--t4-tl-ic-size);
  border: 2px solid var(--t4-ev-bg);
  /* border-color: red; */
  background: var(--t4-ev-bg);
  border-radius: 99em;
}

.tik4-se__m__i__svg,
.tik4-se__m__top-i__i__svg {
  width: 1.5em;
  height: 1.5em;
  flex: 0 0 1.5em;
}

/* pt: phase_title */
.tik4-se__m__pt {
  color: var(--t4-muted);
}

/* top: time_of_play */
.tik4-se__m__top-i__top {
  font-size: var(--t4-txt-lg);
  font-weight: var(--t4-fw-b);
  display: inline;
}

.tik4-se__m__top-i__i {
  display: inline;
  display: none;
  vertical-align: middle;
}

.tik4-se__m__top-i__i__svg {
  display: inline;
}

.tik4-se__c {
  & > *:not(:first-child) {
    margin-top: var(--t4-sp-3);
  }
}


.tik4-live--timeline {
  & .tik4-se__m__i {
    display: flex;
    width: 0;
    left: calc(-1*var(--t4-tl-mr) + -1*var(--t4-tl-w)/2);
  }
  & .tik4-se__m__top-i__i {
    display: none;
  }

  & .tik4-se {
    padding-left: 0;
  }
}



/* plc player content */

.tik4-pl {
  & .tik4-ec__c {
    padding: var(--t4-sp-6);
    padding: 0;
  }
  & .tik4-plcard {
    border: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}

.tik4-plc {
  display: flex;
  align-items: center;
}

.tik4-plc__i {
  position: relative;
  width: 3.5em;
  height: 3.5em;
  flex: 0 0 3.5em;
  margin-right: var(--t4-sp-2);
}

.tik4-plc__i__img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

/* event player title */
.tik4-plc__n__ept {
  color: var(--t4-muted);
  font-size: var(--t4-txt-sm);
}

.tik4-plc__n {
  @extend %tik4-break-safe;
  font-weight: var(--t4-fw-b);
  min-width: 0;
}

.tik4-plc__n__n {
  @extend %tik4-break-safe;
}


/* pld Player detail */
.tik4-pld {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: var(--t4-sp-2);
}
.tik4-event--sm .tik4-pl__pld {
  grid-template-columns: auto 1fr auto 1fr;
}

.tik4-pld__l {
  font-weight: var(--t4-fw-b);
}




/* player card */

.tik4-plcard {
  border: 1px solid var(--t4-sfc-b);
  border-radius: var(--t4-rounded);
  overflow: hidden;
  /* max-width: 300px; */
}

.tik4-event--sm .tik4-plcard {
  display: flex;
  max-width: 100%;
}

.tik4-plcard__i {
  /* background: var(--t4-sfc-bg); */
  border-bottom: 1px solid var(--t4-sfc-b);
}
.tik4-event--sm .tik4-plcard__i {
  border-right: 1px solid var(--t4-sfc-b);
  border-bottom: 0;
  flex: 0 0 33.33%;
}

.tik4-event--md .tik4-plcard__i {
  flex: 0 0 300px;
}

.tik4-plcard__i__img {
  max-height: 300px;
  width: auto;
  height: auto;
}
.tik4-event--sm  .tik4-plcard__i__img {
  max-height: none;
  width: 100%;
}

.tik4-plcard__m {
  padding: var(--t4-sp-4);
  container-type: inline-size;
  container-name: tik4-plcard__m;
  flex-grow: 1;
}
@container tik4-plcard__m (min-width: 28em) {
  .tik4-plcard__pld {
    grid-template-columns: auto 1fr auto 1fr;
  }
}
.tik4-plcard__n {
  font-size: var(--t4-txt-lg);
  font-weight: var(--t4-fw-b);
}
.tik4-plcard__a {
  margin-top: var(--t4-sp-4);
}
.tik4-plcard__pld {
  margin-top: var(--t4-sp-4);
  padding-top: var(--t4-sp-4);
  border-top: 1px solid var(--t4-sfc-b);
}

.tik4-plcard__sm {
  margin-top: var(--t4-sp-4);
  padding-top: var(--t4-sp-4);
  border-top: 1px solid var(--t4-sfc-b);
}

.tik4-plcard__sm__l {
  margin-right: 1em;
  display: inline-flex;
  white-space: nowrap;
  align-items: center;
  flex-direction: column;
}

.tik4-plcard__sm__i {
  top: 0;
  width: 1.1em;
  height: 1.1em;
  flex: 0 0 1.1em;
}

.tik4-plcard__sm__t {
  margin-top: .2em;
  font-size: var(--t4-txt-sm);
}

/* TEAM
.tik4-team {
  display: flex;
  align-items: center;
}

.tik4-team__i {
  margin-right: var(--t4-sp-1);
}

.tik4-team__img {
  width: 1.5em;
  height: 1.5em;
  flex: 0 0 1.5em;
  object-fit: contain;
}

.tik4-team__n {
  font-weight: var(--t4-fw-b);
}
*/

.tik4-se__m__ti,
.tik4-se__m__te {
  font-weight: var(--t4-fw-b);
  display: inline;
  @extend %tik4-break-safe;
}




.tik4-ti-ph {
  /* border-radius: 99em; */
  /* background: var(--t4-sfc-bg); */
  width: 100%;
  aspect-ratio: 1/1;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--t4-muted);
  position: relative;
  opacity: 0.5;
  container-type: inline-size;
}
.tik4-ti-ph__svg {
  width: 100%;
  height: 100%;
}

.tik4-ti-ph__i {
  /* todo  */
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: var(--t4-txt--i);
  font-weight: var(--t4-fw-b);
  font-size: .75em;
  font-size: 65cqw;
  opacity: 0.60;
}
