@define-mixin tik4-rich-text {
  /* CAREFUL!!! this mixin is also imported in editor (../../../editor/css/index.css) !!!! */
  /* CAREFUL!!! this style is also used as a base for the editor (../../../editor/css/components/rich-text.css) !!!! */
  overflow-wrap: break-word;
  word-wrap: break-word;

  & a {
    @extend %tik4-link-style;
  }

  & ul {
    margin: 1em 0;
    list-style-type: disc;
    padding-left: 1em;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  & ol {
    margin: 1em 0;
    list-style-type: decimal;
    padding-left: 1em;
    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }

  & h2 {
    font-size: var(--t4-txt-xl);
    line-height: var(--t4-txt-xl-lh);
    margin-top: 0;
    margin-bottom: 0;
    & ~ * {
      margin-top: .5em;
    }
    &:not(:first-child) {
      margin-top: 1.2em;
    }
  }

  & blockquote {
    /* color: var(--t4-txt-quote); */
    margin: 1em 0;
    padding-left: 1.25em;
    /* border-left: .3em solid; */
    position: relative;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      width: .3em;
      background: currentColor;
      border-radius: .15em;
      opacity: 0.5;
    }
  }
}
