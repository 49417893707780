.tik4-tags {
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -.2em;
  margin-bottom: var(--t4-sp-4);
  justify-content: flex-start;
  min-width: 0;
}

.tik4-tag,
button.tik4-tag {
  font-size: var(--t4-txt-sm);
  color: var(--t4-muted);
  padding: .1em .6em;
  border: 1px solid;
  border-radius: var(--t4-tag-rounded);
  display: inline-flex;
  align-items: center;
  margin: .2em;
  max-width: 100%;
}

.tik4-tag--button {
  @mixin tik4-button-reset;
  /* @extend %tik4-button-reset; */
  cursor: pointer;
  &:hover {
    color: var(--t4-txt);
  }
}
.tik4-tag--selected.tik4-tag--selected {
  color: var(--t4-secondary);
  /* &:hover {
    color: var(--t4-secondary);
  } */
}

.tik4-tag__img {
  margin-right: .4em;
  flex: 0 0 calc(1em / 0.875);
  width: calc(1em / 0.875);
}

.tik4-tag__name {
  max-width: 100%;
  @extend %tik4-break-safe;
  min-width: 0;
}


/* Theme options */
.tik4-live--event-tag-position-off .tik4-tags {
  display: none;
}
.tik4-live--event-tag-position-bottom .tik4-tags {
  order: 110;
  margin-bottom: 0;
  margin-top: var(--t4-sp-6);
}





.tik4-tag-filter {
  /* padding: 0 var(--t4-ev-p-x); */
  margin: var(--t4-sp-8) 0;
  &:first-child {
    margin-top: 0;
  }
}

.tik4-tag-filter__label {
  /* font-size: var(--t4-txt-sm); */
  font-weight: var(--t4-fw-b);
}

/* lf: list filter */
.tik4-tag-filter--lf {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: baseline;
}

.tik4-tag-filter__list {
  list-style-type: none;
  margin: 0 -.2em 0;
  padding: 0;
  grid-area: 2/1/3/-1;
}

.tik4-tag-filter__list__item {
  display: inline-block;
  max-width: 100%;
  vertical-align: bottom;
}

/* remove this */

.tik4-tag-filter--tfs {
  display: flex;
  column-gap: 0.75em;
  align-items: baseline;
  & .tik4-tfs-c {
    min-width: 0;
  }
}

.tik4-rs--tfs {
  width: max-content;
  min-width: 12em;

  & .tik4-rs__control {
    border-radius: 1.1em;
  }
  & .tik4-rs__multi-value {
    border-radius: var(--t4-tag-rounded);
  }
  & .tik4-rs__value-container.tik4-rs__value-container--is-multi {
    padding-left: 0.25em;
  }
  & .tik4-rs__dropdown-indicator {
    padding-right: 0.6em;
  }
}

