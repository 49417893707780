/* Set-based Scoreboard ssb */
.tik4-ssb {
  border: 1px solid var(--t4-sfc-b);
  border-radius: var(--t4-rounded);
  padding: var(--t4-sp-6) var(--t4-ev-p-x);
}

/* sb: scoreboard */
.tik4-ssb__sb {
  display: flex;
  justify-content: space-between;

}

/* lr: label row (item) */
.tik4-ssb__lr {
  height: 2em;
  display: flex;
  align-items: center;
}
/* sr: score row (item) */
.tik4-ssb__sr {
  height: 2em;
  display: flex;
  align-items: center;
}
.tik4-ssb--m .tik4-ssb__sr {
  font-size: var(--t4-txt-lg);
}

.tik4-ssb--t .tik4-ssb__sr{
  height: 2.4em;
}

/* ts: teams */
.tik4-ssb__ts {
  max-width: 50%;
}

/*  */
.tik4-ssb__t__n {
  min-width: 0;
}
.tik4-ssb__t__n__sn {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.tik4-ssb__t__n__n {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  display: none;
}
.tik4-ssb--m .tik4-ssb__t__n {
  font-size: var(--t4-txt-lg);
}

/* sw: scores wrapper */
.tik4-ssb__sw {
  display: flex;
  min-width: 0;
  overflow-x: auto;
  flex-direction: row-reverse;
  flex-grow: 1;
}

/* swi: scores wrapper inner */
.tik4-ssb__swi {
  flex-grow: 1;
}

/* s: scores */
.tik4-ssb__s {
  display: flex;
  min-width: 100%;
  justify-content: space-evenly;
}

/* si: score item */
.tik4-ssb__si {
  padding: 0 var(--t4-sp-3);
}

.tik4-ssb__sl {
  justify-content: center;
}

/* sv: score value */
.tik4-ssb__sv {
  justify-content: center;
}
.tik4-ssb--m .tik4-ssb__sv {
  font-size: var(--t4-txt-lg);
}

/* tl: team label */
.tik4-ssb__tl,
.tik4-ssb__sl {
  color: var(--t4-muted);
}

/* t: team */
.tik4-ssb__t {
  display: flex;
  font-weight: var(--t4-fw-b);
  padding-right: var(--t4-sp-3);
}

.tik4-ssb__t__i {
  flex: 0 0 1.25em;
  width: 1.25em;
  height: 1.25em;
  margin-right: var(--t4-sp-2);
}
.tik4-ssb--t .tik4-ssb__t__i {
  flex: 0 0 2em;
  width: 2em;
  height: 2em;
}

.tik4-ssb--m .tik4-ssb__t__i {
  flex: 0 0 1.75em;
  width: 1.75em;
  height: 1.75em;
}

.tik4-ssb__t__img,
.tik4-ssb__t__ifb {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

/* ss: summed score */
.tik4-ssb__ss {
  /* text-align: right; */
  padding-left: var(--t4-sp-3);
  /* & .tik4-ssb__sv,
  & .tik4-ssb__sl {
    justify-content: flex-end;
  } */
  & .tik4-ssb__sv {
    font-weight: var(--t4-fw-b);
  }
}

/* es: empty score */
.tik4-ssb__es {
  color: var(--t4-muted);
}


.tik4-ssb--m {
  margin-bottom: var(--t4-sp-8);
  &:not(:first-child) {
    margin-top: var(--t4-sp-8);
  }
}

.tik4-live--xs .tik4-ssb--m,
.tik4-event--xs .tik4-ssb  {
  & .tik4-ssb__t__n__sn {
    display: none;
  }

  & .tik4-ssb__t__n__n {
    display: block;
  }
}
