.tik4-ec {
  border-radius: var(--t4-rounded);
  border: 1px solid var(--t4-sfc-b);
}

.tik4-ec__h {
  @mixin tik4-button-reset;
  transition: all var(--t4-duration);
  padding: var(--t4-sp-3);
  border-radius: var(--t4-rounded);
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  &:hover:not(:disabled) {
    filter: var(--t4-hover-saturate);
    background: var(--t4-sfc-bg);
    & .tik4-ec__h__ch {
      color: var(--t4-txt);
    }
  }
  &:active:not(:disabled) {
    filter: var(--t4-active-saturate);
    background: transparent;
  }
  &:focus-visible {
    outline-color: var(--t4-txt);
  }
}

.tik4-ec__h__c {
  min-width: 0;
}

.tik4-ec__h__ch {
  color: var(--t4-muted);

}

.tik4-ec__h__ch--ex {
  transform: rotate(180deg);
}


.tik4-ec__c {
  border-top: 1px solid var(--t4-sfc-b);
  padding: var(--t4-sp-3);
}



.tik4-ca {
  border: 1px solid var(--t4-sfc-b);
  border-radius: var(--t4-rounded);
  padding: var(--t4-sp-3);
}
