@define-mixin setColorScheme $scheme, $schemeInvert {
  /* Please add any new color from tik4-varibles.css here */

  /* COLORS */
  --t4-bg: var(--t4-bg--$(scheme));
  --t4-bg--i: var(--t4-bg--$(schemeInvert)); /* invert */
  --t4-primary: var(--t4-primary--$(scheme));
  --t4-secondary: var(--t4-secondary--$(scheme));
  --t4-txt: var(--t4-txt--$(scheme));
  --t4-txt--i: var(--t4-txt--$(schemeInvert)); /* invert */
  --t4-warn: var(--t4-warn--$(scheme));
  --t4-muted: var(--t4-muted--$(scheme));
  /* --t4-txt-quote: var(--t4-txt-quote--$(scheme)); */
  --t4-link: var(--t4-link--$(scheme));
  --t4-error: var(--t4-error--$(scheme));

  /* EVENT */
  --t4-ev-bg: var(--t4-bg--$(scheme));
  --t4-ev-b: var(--t4-ev-b--$(scheme)); /* border */
  --t4-ev-h-bg: var(--t4-ev-h-bg--$(scheme)); /* highlight bg */
  --t4-ev-h-b-bg: var(--t4-ev-h-b-bg--$(scheme)); /* highlight border */
  --t4-ev-s-bg: var(--t4-ev-s-bg--$(scheme)); /* summary bg */
  --t4-ev-p-bg: var(--t4-ev-p-bg--$(scheme)); /* pinned bg */

  /* --t4-tl-bg: var(--t4-tl-bg--$(scheme)); */
  /* --t4-tl-dot-bg: var(--t4-tl-dot-bg--$(scheme)); */
  /* --t4-tl-ic-b: var(--t4-tl-ic-b--$(scheme)); */

  /* FORM */
  --t4-ff-bg: var(--t4-ff-bg--$(scheme));
  --t4-ff-b: var(--t4-ff-b--$(scheme)); /* border */
  --t4-ff-b-f: var(--t4-ff-b-f--$(scheme));

  /* WEB EMBED */
  /*--t4-we-b: var(--t4-we-b--$(scheme)); /* border */
  /*--t4-we-b-h: var(--t4-we-b-h--$(scheme)); /* border hover */
  /* --t4-we-to-dis: var(--t4-we-to-dis--$(scheme)); */

  /* surface (srfc */
  --t4-sfc-bg: var(--t4-sfc-bg--$(scheme));
  --t4-sfc-b: var(--t4-sfc-b--$(scheme));


  /* CARDS */
  --t4-ca-sh: var(--t4-ca-sh--$(scheme));
}

/* There are multiple ways to decide when to use dark mode
- set wrapper-class .tik4-live--on-dark
- use media query
- probably an option like darkmode:(false:auto:true) makes sense
if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    // dark mode
}
window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', e => {
    const newColorScheme = e.matches ? "dark" : "light";
});
 */

 /* LIGHT COLORS */
.tik4-live--on-dark.tik4-live--invert-highlight .tik4-event--highlight,
.tik4-live--on-dark.tik4-live--invert-summary .tik4-event--summary,
.tik4-live--on-dark.tik4-live--invert-pin .tik4-event--pin,
.tik4--on-light,
.tik4--on-auto {
  /* careful, this sets a lot of variables.. so use it sparcefully to avoid bloating your css */
  @mixin setColorScheme l, d;
}


/* DARK COLORS */

/* setting all colors to their dark variant */
/* @media (prefers-color-scheme: dark) */
.tik4-live--on-light.tik4-live--invert-highlight .tik4-event--highlight,
.tik4-live--on-light.tik4-live--invert-summary .tik4-event--summary,
.tik4-live--on-light.tik4-live--invert-pin .tik4-event--pin,
.tik4-live--on-auto.tik4-live--invert-highlight .tik4-event--highlight,
.tik4-live--on-auto.tik4-live--invert-summary .tik4-event--summary,
.tik4-live--on-auto.tik4-live--invert-pin .tik4-event--pin,
.tik4--on-dark  {
  /* careful, this sets a lot of variables.. so use it sparcefully to avoid bloating your css */
  @mixin setColorScheme d, l;
}

/* .tik4--on-dark {
  background-color: var(--t4-bg--dark);
} */



/* @media (prefers-color-scheme: light) {
  .tik4-live--on-dark.tik4-live--invert-highlight .tik4-event--highlight,
  .tik4-live--on-dark.tik4-live--invert-pin .tik4-event--pin,
  .tik4--on-auto {
    @mixin setColorScheme light, dark;
  }
}
*/

@media (prefers-color-scheme: dark) {
  .tik4--on-auto  {
    /* careful, this sets a lot of variables.. so use it sparcefully to avoid bloating your css */
    @mixin setColorScheme d, l;
  }

  .tik4-live--on-auto.tik4-live--invert-highlight .tik4-event--highlight,
  .tik4-live--on-auto.tik4-live--invert-summary .tik4-event--summary,
  .tik4-live--on-auto.tik4-live--invert-pin .tik4-event--pin {
    @mixin setColorScheme l, d;
  }

  /* .tik4--on-auto {
    background-color: var(--t4-bg--dark);
  } */
}


.tik4-live.tik4-live--invert-highlight .tik4-event--highlight {
  --t4-ev-bg: var(--t4-ev-h-bg)
}

.tik4-live.tik4-live--invert-summary .tik4-event--summary {
  --t4-ev-bg: var(--t4-ev-s-bg);
}

.tik4-live.tik4-live--invert-pin .tik4-event--pin {
  --t4-ev-bg: var(--t4-ev-p-bg)
}
