tickaroo-liveblog {
  min-width: 0;
  /* slideshow infinity width bugfix */
}

.tik4-live {
  min-width: var(--t4-min-width);
  color: var(--t4-txt);
  line-height: var(--t4-txt-lh);
  font-size: var(--t4-txt-base);
  text-align: left;
}
