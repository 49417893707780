.tik4-sharing {
  display: none;
}

.tik4-sharing__item__button {
  @mixin tik4-button-reset;
  /* @extend %tik4-button-reset; */
  color: inherit;
  display: flex;
  align-items: baseline;
}

.tik4-sharing__item {
  position: relative;
}

.tik4-sharing__item__tooltip {
  pointer-events: none;
  position: absolute;
  background: var(--t4-bg--i);
  color: var(--t4-txt--i);
  left: 50%;
  top: -1.5em;
  transform: translateX(-50%);
  padding: .2em .5em;
  border-radius: var(--t4-rounded);
  white-space: nowrap;
  opacity: 0;
  transition: opacity .15s ease-in-out .3s, top .15s ease-in-out .3s, background-color 0.0001s, color 0.0001s;
  z-index: 1;
}

.tik4-sharing__item--last .tik4-sharing__item__tooltip {
  /* margin-left: calc(-1/2 * var(--t4-ev-p-x)) */
  transform: translateX(-100%);
  left: 100%;
}

.tik4-live--sharing-colors {
  & .tik4-sharing__item--twitter .tik4-sharing__item__button {
    color: #1d9bf0;
  }
  & .tik4-sharing__item--facebook .tik4-sharing__item__button {
    color: #1877F2;
  }
  & .tik4-sharing__item--email .tik4-sharing__item__button {
    color: #0ECE65;
  }
  & .tik4-sharing__item--link .tik4-sharing__item__button {
    color: #F0441E;
  }
}

/* expanded sharing */
.tik4-live--sharing-expanded {
  & .tik4-sharing {
    display: flex;
    margin: calc(var(--t4-sp-8) - var(--t4-sp-1-5)) 0 calc(-1 * var(--t4-sp-1-5)) auto;
  }

  & .tik4-sharing__items {
    margin: 0 calc(-1 * var(--t4-sp-3));
    display: flex;
    align-items: baseline;
  }
  & .tik4-sharing__item {
    margin: 0 var(--t4-sp-1-5);
  }
  & .tik4-sharing__item__button {
    color: var(--t4-muted);
    padding: var(--t4-sp-1-5);
    &:hover {
      color: var(--t4-link);
    }
    @media (hover: hover) {
      &:hover {
        & + .tik4-sharing__item__tooltip {
          opacity: 1;
          top: -2em;

          /* display: block; */
          /* animation: tik4-show-sharing-tooltip .3s ease-in-out .2s forwards; */
        }
      }
    }
    /* &:hover, */
    &:focus-visible {
      & + .tik4-sharing__item__tooltip {
        opacity: 1;
        top: -2em;

        /* display: block; */
        /* animation: tik4-show-sharing-tooltip .3s ease-in-out .2s forwards; */
      }
    }
  }
  & .tik4-sharing__item__icon {
    width: 1em;
    height: 1em;
    flex: 0 0 1em;
    top: 0;
  }
  & .tik4-sharing__item__text {
    display: none;
  }
  & .tik4-sharing__items__close,
  & .tik4-sharing__open {
    display: none;
  }

  /* Safari-hack  (sh)*/
  & .tik4-sharing__item__sh {
    background: var(--t4-bg);
    position: absolute;
    bottom: 1.5em;
    transform: translateX(50%);
    right: 50%;
    border-radius: var(--t4-rounded);
    padding: var(--t4-sp-4) var(--t4-sp-8) var(--t4-sp-4) var(--t4-sp-4);
    box-shadow: 0 .125em .5em 0 rgb(0,0,0,.2), 0 .125em 1.5em -.125em rgb(0,0,0,.3);
    z-index: 2;
    max-width: 14em;
    & ~ .tik4-sharing__item__tooltip {
      display: none;
    }
  }
  & .tik4-sharing__item__sh__button {
    margin-top: var(--t4-sp-2);
    width: 100%;
  }
  & .tik4-sharing__item__sh__close {
    @mixin tik4-button-reset;
    /* @extend %tik4-button-reset; */
    position: absolute;
    top: 0;
    right: 0;
    color: inherit;
    opacity: 0.6;
    padding: 0 var(--t4-sp-2-5);
    height: 2em;
    &:hover {
      opacity: 1;
      color: var(--t4-link);
    }
  }

}



/* @keyframes tik4-show-sharing-tooltip {
  0% {
    opacity: 0;
  }
  100% {
    top: -1.5em;
    opacty: 1;
  }
} */

/* Compact sharing */
.tik4-live--sharing-compact {
  & .tik4-sharing {
    display: block;
    position: relative;
    margin: var(--t4-sp-8) 0 0 auto;
  }
  & .tik4-sharing__items {
    background: var(--t4-bg);
    display: none;
    flex-direction: column;
    position: absolute;
    bottom: 1.5em;
    right: 0;
    color: var(--t4-txt);
    border-radius: var(--t4-rounded);
    padding: var(--t4-sp-4) var(--t4-sp-8) var(--t4-sp-4) 0;
    box-shadow: 0 .125em .5em 0 rgb(0,0,0,.2), 0 .125em 1.5em -.125em rgb(0,0,0,.3);
  }
  & .tik4-sharing--open {
    & .tik4-sharing__items {
      z-index: 2;
      animation: tik4-fade-in-slide-up var(--t4-duration) ease-in-out forwards;
      display: flex;
    }
  }
  & .tik4-sharing__item__button {
    margin-bottom: var(--t4-sp-0-5);
    padding: var(--t4-sp-1) var(--t4-sp-6);
    width: 100%;
    white-space: nowrap;
    &:hover {
      color: var(--t4-secondary);
    }
  }
  & .tik4-sharing__item__tooltip {
    transition: opacity .15s ease-in-out;
  }
  & .tik4-sharing__item__tooltip--copied {
    opacity: 1;
    top: 0;
    left: 2.8em;
    transform: none;
  }
  & .tik4-sharing__item__icon {
    margin-right: .375em;
  }
  & .tik4-sharing__items__close {
    position: absolute;
    right: 0;
    top: 0;
  }

  & .tik4-sharing__items__close__button {
    @mixin tik4-button-reset;
    /* @extend %tik4-button-reset; */
    color: inherit;
    opacity: 0.6;
    padding: 0 var(--t4-sp-2-5);
    height: 2em;
    &:hover {
      opacity: 1;
      color: var(--t4-link);
    }
  }

  & .tik4-sharing__items__close__text {
    display: none;
  }

  & .tik4-sharing__open__button {
    @mixin tik4-button-reset;
    /* @extend %tik4-button-reset; */
    color: var(--t4-muted);
    display: flex;
    align-items: baseline;
    &:hover {
      color: var(--t4-link);
    }
  }

  & .tik4-sharing__open__icon {
    margin-right: var(--t4-sp-1);
  }

  /* Safari-hack  (sh)*/
  & .tik4-sharing__item__sh {
    width: 10em;
    padding-left: var(--t4-sp-6);
    & ~ .tik4-sharing__item__button,
    & ~ .tik4-sharing__item__tooltip {
      display: none;
    }
  }
  & .tik4-sharing__item__sh__button {
    margin-top: var(--t4-sp-1);
    width: 100%;
  }
  & .tik4-sharing__item__sh__close {
    display: none;
  }
}
