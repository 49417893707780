.tik4-tw {
  min-width: var(--t4-min-width);
  color: var(--t4-txt);
  line-height: var(--t4-txt-lh);
  font-size: var(--t4-txt-base);
  text-align: left;

  --t4-tw-tl-c: var(--t4-tw-txt);
}

.tik4-tw__container {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  column-gap: var(--t4-sp-1);
  row-gap: var(--t4-sp-2);;
  justify-content: space-between;
}

.tik4-tws__tl,
.tik4-tws__dt--tl {
  display: none;
}

/* Timeline */
.tik4-tw--tl {
  & .tik4-tws__tl {
    display: flex;
  }
  & .tik4-tws__icon:not(.tik4-tws__icon--tl) {
    display: none;
  }

  & .tik4-tws__sl .slick-arrow {
    transform: translate(0, calc(-50% + .5em))
  }

  & .tik4-twl__tl {
    display: block;
  }
}
/* Timeline with time */
.tik4-tw--tl--t {
  & .tik4-tws__tl {
    --t4-tw-tl-c: var(--t4-tw-muted);

  }
  & .tik4-tws__dt--tl {
    display: block;
  }
  & .tik4-tws__i__h,
  & .tik4-tws__tl__d {
    display: none;
  }
}
