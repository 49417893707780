/* gs: game summary */

.tik4-gs {
  margin-top: var(--t4-sp-4);
  /* padding: 0 var(--t4-sp-7) 0; */
  margin-bottom: var(--t4-sp-8);
  /*
  &:not(:first-child) {
    margin-top: var(--t4-sp-10);
  } */
}


.tik4-gsg {
  display: grid;
  grid-gap: 0.25em;
  grid-template-columns: 50% 50%;
  grid-auto-flow: dense;
  /* grid-template-columns: 100%; */
}

/* gsg__i: game summary goals item */
.tik4-gsg__i {
  display: flex;
  font-size: var(--t4-txt-sm);
}

.tik4-gsg__i--ht {
  grid-column-start: 1;
  grid-column-end: 2;
  justify-content: flex-end;
  /* padding-right: 50%; */
  & .tik4-gsg__p {
    text-align: right;
    padding-right: .5em;
  }
  /* & .tik4-gsg__top {
    padding-right: .5em;
  } */
}

.tik4-gsg__i--at {
  grid-column-start: 2;
  /* grid-column-start: 1; */
  grid-column-end: -1;
  /* padding-left: 50%; */
  & .tik4-gsg__p {
    order: 2;
    padding-left: .5em;
  }
  /* & .tik4-gsg__top { */
    /* text-align: right; */
    /* padding-left: .5em; */
  /* } */
}

.tik4-gsg__p {
  min-width: 0;
  display: flex;
  align-items: baseline;
  cursor: pointer;
}

.tik4-gsg__p__n {
  min-width: 0;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.tik4-gsg__p__og {
  font-size: var(--t4-txt-xs);
}

.tik4-gsg__top {
  color: var(--t4-muted);
  padding: 0 .5em;
  min-width: 2.5em;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 0 0 min-content;
  max-width: 50%;
  text-align: center;

}

