.tik4-body--modal-open {
  overflow: hidden;
}
@media screen and (max-width: 40em) {
  .sm\:tik4-html--modal-open-scroll {
    overflow: hidden; /*needed for ios safari */
  }
  .sm\:tik4-body--modal-open-scroll {
    overflow: hidden;
    /*position: relative;/*needed for ios safari */
    /*height: 100%;/*needed for ios safari */
  }
  .tik4-modal__overlay {
    /* iOS Safari hack: activates 1px scrolling to prevent scrolling of main page */
    overflow: auto;
    &:after {
      content: '';
      position: absolute;
      right: 0;
      left: 0;
      top: calc(100% - 1px);
      height: 2px;
      display: block;
      width: 100%;
      background: transparent;
    }
    /* end hack */
  }
}

.tik4-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0, 0.75);

  z-index: 99999999;

  transition: opacity .15s ease-out;
  opacity: 0;

  &.tik4-modal__overlay--after-open {
    opacity: 1;
  }
  &.tik4-modal__overlay--before-close {
    opacity: 0;
  }
}

.tik4-modal__content {
  position: absolute;
  top: var(--t4-modal-content-margin);
  left: var(--t4-modal-content-margin);
  right: var(--t4-modal-content-margin);
  bottom: var(--t4-modal-content-margin);
  /* border: 1px solid ccc; */
  background: var(--t4-bg);
  color: var(--t4-txt);
  line-height: var(--t4-txt-lh);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: var(--t4-rounded-lg);
  outline: none;
  font-size: var(--t4-txt-base);
  /* padding: 20px; */

  transition: transform .15s ease-out, opacity .15s ease-out;
  box-shadow: 0 .2em 2em rgba(0,0,0,0.4);
  opacity: 0;

  &.tik4-modal__content--after-open {
    /* transform: translate(0, 0); */
    opacity: 1;
  }
  &.tik4-modal__content--before-close {
    opacity: 0;
  }
}

.tik4-modal--bottom {
  & .tik4-modal__content {
    top: auto;
    bottom: 0;
    border-radius: 0;
    left: 0;
    right: 0;

    transform: translate(0, 5em);
    &.tik4-modal__content--after-open {
      transform: translate(0, 0);
    }
    &.tik4-modal__content--before-close {
      transform: translate(0, 5em);
    }
  }
}


@media screen and (min-width: 40em) {
  .sm\:tik4-modal--right {
    & .tik4-modal__content {
      border-radius: 0;
      top: 0;
      bottom: 0;
      left: auto;
      right: 0;
      max-width: calc(100vw - var(--t4-modal-content-margin));
      transform: translate(5em, 0);

      &.tik4-modal__content--after-open {
        transform: translate(0, 0);
      }
      &.tik4-modal__content--before-close {
        transform: translate(5em, 0);
      }
    }
  }
}


.tik4-modal__header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.tik4-modal__header-title {
  font-size: var(--t4-txt-lg);
  font-weight: var(--t4-fw-b);
  padding: var(--t4-sp-4) var(--t4-sp-6);
}

.tik4-modal__close {
  @mixin tik4-button-reset;
  /* @extend %tik4-button-reset; */
  color: inherit;
  transition: opacity var(--t4-duration);
  flex: 0 0 2.2em;
  width: 2.2em;
  height: 2.2em;
  font-size: 1.25em;
  /* background: no-repeat center var(--t4-icon-x); */
  opacity: 0.6;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    opacity: 1;
  }
}
.tik4-modal__close__icon {
  top: 0;
  width: 1em;
  height: 1em;
  flex: 0 0 1em;
}
