/* Global */
.tik4-sb__ph,
.tik4-sb__top {
  white-space: nowrap;
  overflow-x: hidden;
  overflow-x: clip;
  text-overflow: ellipsis;
}

.tik4-sb__t__n__sn,
.tik4-sb__t__n__n {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* d: DEFAULT */

.tik4-sb--d {
  display: grid;
  /* grid-template-columns: 1fr 2em auto 2em 1fr; */
  /* grid-template-columns: minmax(0, 1fr) auto auto auto minmax(0, 1fr); */
  grid-template-columns: minmax(0, 1fr) fit-content(25%) auto fit-content(25%) minmax(0, 1fr);
  border: 1px solid var(--t4-sfc-b);
  border-radius: var(--t4-rounded);
  padding-bottom: var(--t4-sp-6);
  & > div {
    /* border: 1px dotted rgba(255, 0, 0, 0.1); */
  }

  &:after {
    content: '';
    grid-area: 1/1/3/-1;
    background: var(--t4-sfc-bg);
    /* border-bottom: 1px solid var(--t4-sfc-b); */
  }

  & .tik4-sb__ph {
    grid-area: 1/1/2/-1;
    text-align: center;
    color: var(--t4-muted);
    font-size: var(--t4-txt-sm);
    position: relative;
    z-index:1;
    padding: var(--t4-sp-1) var(--t4-ev-p-x);
  }

  & .tik4-sb__top {
    grid-area: 2/1/3/-1;
    font-weight: var(--t4-fw-b);
    text-align: center;
    position: relative;
    z-index:1;
    padding: var(--t4-sp-1) var(--t4-ev-p-x);
    &:not(:first-child) {
      padding-top: 0;
      margin-top: calc(-1 * var(--t4-sp-1));
    }
  }

  & .tik4-sb__sp {
    padding-top: var(--t4-sp-6);
  }

  & .tik4-sb__t {
    display: flex;
    flex-direction: column;
    padding: 0 var(--t4-sp-3);
  }

  & .tik4-sb__t--ht {
    grid-area: 4/1/5/2;
    align-items: flex-end;
    padding-left: var(--t4-ev-p-x);
  }

  & .tik4-sb__t__n--ht {
    text-align: right;
  }

  & .tik4-sb__t__n {
    margin-top: var(--t4-sp-1);
    max-width: 100%;
  }
  &.tik4-sb--m .tik4-sb__t__n {
    font-size: var(--t4-txt-base);
    font-weight: var(--t4-fw-b);
  }

  & .tik4-sb__t__n__n {
    display: none;
  }

  & .tik4-sb__s--ht {
    text-align: right;
    padding-right: var(--t4-sp-1);
    grid-area: 4/2/5/3;
  }

  & .tik4-sb__s--at {
    padding-left: var(--t4-sp-1);
    grid-area: 4/4/5/5;
  }

  & .tik4-sb__d {
    text-align: center;
    grid-area: 4/3/5/4;
  }


  & .tik4-sb__t--at {
    grid-area: 4/5/5/-1;
    align-items: flex-start;
    padding-right: var(--t4-ev-p-x);
  }

  & .tik4-sb__s,
  & .tik4-sb__d {
    font-weight: var(--t4-fw-b);
    font-size: var(--t4-txt-xl);
  }
  & .tik4-sb__s {
    overflow: hidden;
  }

  /* & .tik4-sb__sp {
    padding-top: var(--t4-sp-3);
  } */
  & .tik4-sb--m .tik4-sb__sp {
    padding-top: var(--t4-sp-6)
  }

  & .tik4-sb__t__i {
    flex: 0 0 2em;
    height: 2em;
    width: 2em;
  }
  &.tik4-sb--m .tik4-sb__t__i {
    flex: 0 0 2.5em;
    height: 2.5em;
    width: 2.5em;
  }

  & .tik4-sb__t__img,
  & .tik4-sb__t__iph {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  /* & .tik4-sb__t__iph {

  } */

  &.tik4-sb--e:not(.tik4-sb--d-t) {
    & .tik4-sb__t__n {
      font-size: var(--t4-txt-sm);
    }
  }
}

.tik4-sb--d-l {
  & .tik4-sb__t__n {
    display: none;
  }
}

.tik4-sb--d-t {
  & .tik4-sb__t__i {
    display: none;
  }

  & .tik4-sb__t,
  & .tik4-sb__s,
  & .tik4-sb__d {
    align-self: baseline;
  }
}

.tik4-live--xs .tik4-sb--d.tik4-sb--m,
.tik4-event--xs .tik4-sb--d.tik4-sb--e  {
  & .tik4-sb__t__n__sn {
    display: none;
  }

  & .tik4-sb__t__n__n {
    display: block;
  }
}




/* TABLE */


.tik4-sb--t {
  display: grid;
  grid-template-columns: minmax(50%,1fr) auto;
  border: 1px solid var(--t4-sfc-b);
  border-radius: var(--t4-rounded);
  /* & > div {
    border: 1px dotted rgba(255, 0, 0, 0.1);
  } */

  &:after {
    content: '';
    grid-area: 1/1/2/-1;
    background: var(--t4-sfc-bg);
    /* border-bottom: 1px solid var(--t4-sfc-b); */
  }

  & .tik4-sb__d {
    display: none;
  }

  & .tik4-sb__ph {
    grid-area: 1/1/2/2;
    padding: var(--t4-sp-1) var(--t4-sp-4);
    font-size: var(--t4-txt-sm);
    color: var(--t4-muted);
  }
  &.tik4-sb--m .tik4-sb__ph,
  &.tik4-sb--m .tik4-sb__top {
    font-size: var(--t4-txt-base);
  }

  & .tik4-sb__top {
    grid-area: 1/2/2/-1;
    padding: var(--t4-sp-1) var(--t4-sp-4);
    font-size: var(--t4-txt-sm);
    color: var(--t4-muted);
    text-align: right;
  }

  & .tik4-sb__sp {
    grid-area: 2/1/3/-1;
  }

  & .tik4-sb__s {
    align-self: center;
    font-weight: var(--t4-fw-b);
    text-align: right;
    overflow: hidden;
  }
  &.tik4-sb--m {
    font-size: var(--t4-txt-lg);
  }

  & .tik4-sb__s--ht {
    grid-area: 3/2/4/-1;
  }

  & .tik4-sb__s--at {
    grid-area: 4/2/5/-1;
  }


  & .tik4-sb__t {
    align-self: center;
    display: flex;
    align-items: center;
  }

  & .tik4-sb__t__n {

  }
  &.tik4-sb--m .tik4-sb__t__n {
    font-size: var(--t4-txt-lg);
    font-weight: var(--t4-fw-b);
  }
  & .tik4-sb__t__n__sn {
    display: none;
  }

  & .tik4-sb__t__i {
    margin-right: var(--t4-sp-2);
    flex: 0 0 1.5em;
    height: 1.5em;
  }
  &.tik4-sb--m .tik4-sb__t__i {
    flex: 0 0 2em;
    height: 2em;
  }

  & .tik4-sb__t__img,
  & .tik4-sb__t__iph {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }

  & .tik4-sb__t--ht,
  & .tik4-sb__s--ht {
    padding: var(--t4-sp-4) var(--t4-sp-4) var(--t4-sp-1);
  }

  & .tik4-sb__t--at,
  & .tik4-sb__s--at {
    padding: var(--t4-sp-1) var(--t4-sp-4) var(--t4-sp-4);
  }

  /* MAIN TABLE */
  &.tik4-sb--m {
    & .tik4-sb__t,
    & .tik4-sb__ph {
      padding-left: var(--t4-ev-p-x);
    }
    & .tik4-sb__s,
    & .tik4-sb__top {
      padding-right: var(--t4-ev-p-x);
    }

    & .tik4-sb__ph,
    & .tik4-sb__top {
      padding-top: var(--t4-sp-2);
      padding-bottom: var(--t4-sp-2);
    }

    & .tik4-sb__t--ht,
    & .tik4-sb__s--ht {
      padding-top: calc(.8 * var(--t4-ev-p-x));
    }
    & .tik4-sb__t--at,
    & .tik4-sb__s--at {
      padding-bottom: calc(.8 * var(--t4-ev-p-x));
    }
  }
}

.tik4-sb--t-t {
  & .tik4-sb__t__i {
    display: none;
  }
}


/* MAIN */
.tik4-sb--m {
  /* margin-bottom: var(--t4-sp-10); */
  &:not(:first-child) {
    margin-top: var(--t4-sp-8);
  }
  & + div:not(.tik4-gs) {
    margin-top: var(--t4-sp-8);
  }
}
