.tik4-tw__empty {
  background: var(--t4-tw-sfc-bg);
  border-radius: var(--t4-rounded);
  min-height: calc(1em * var(--t4-txt-lh) * 4);
  width: 100%;
  flex: 0 0 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: var(--t4-sp-4);
  margin-top: var(--t4-sp-2);
  text-align: center;
}
