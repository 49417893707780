.tik4-we-twitter {
  /* & .twitter-tweet:not(.twitter-tweet--preview) { */
  & blockquote.twitter-tweet:not(.twitter-tweet-rendered) {
    display: block;
    max-width: 550px;
    border: 1px solid rgb(207, 217, 222);
    border-radius: 12px;
    padding: 11px 15px;
    font-size: 14px;
    font-weight: var(--t4-fw-b);
    & p {
      font-weight: normal;
    }
  }
}
.tik4-event--sm {
  & .tik4-we-twitter {
    /* & .twitter-tweet:not(.twitter-tweet--preview) { */
    & blockquote.twitter-tweet:not(.twitter-tweet-rendered) {
      font-size: 19px
    }
  }
}


/* .tik4-we--twitter {
  & .tik4-we-simple__title {
    font-size: var(--t4-txt-base)
  }
} */
