.tik4-we-simple + .tik4-we-toggle {
  margin-top: var(--t4-sp-4);
}
.tik4-we-toggle {
  display: flex;
  margin-top: var(--t4-sp-3);
  padding-left: 2.3em;
  position: relative;
}

/* double class for stronger selector (to overwrite style if selector like '[type=checkbox]:checked+label:before, [type=radio]:checked+label:before' is used by embedding page */
.tik4-we-toggle__label.tik4-we-toggle__label {
  position: relative;
  display: block;
  &:before {
    box-sizing: border-box;
    cursor: pointer;

    left: -2.3em;
    width: 1.75em;
    pointer-events: all;
    border-radius: var(--t4-rounded-lg);

    transition: var(--t4-duration);

    position: absolute;
    top: 0;
    display: block;
    height: 1em;
    content: "";
    border: 1px solid var(--t4-muted);
    z-index: 1;
  }
  &:after {
    top: 2px;
    left: calc(-2.3em + 2px);
    width: calc(1em - 4px);
    height: calc(1em - 4px);
    background-color: var(--t4-muted);
    border-radius: var(--t4-rounded-lg);
    transition: var(--t4-duration) ease-in-out;
    position: absolute;
    display: block;
    content: "";
    z-index: 2;
  }
}

.tik4-we-toggle__checkbox.tik4-we-toggle__checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  &:checked ~ {
    &:before {
      color: var(--t4-ev-bg);
      border-color: var(--t4-secondary);
      background-color: var(--t4-secondary);
      content: '';
    }
    &:after {
      background-color: var(--t4-ev-bg);
      transform: translateX(.75em);
    }
  }
  &:focus-visible ~ {
    &:before {
      outline: 1px dotted;
      outline-offset: 2px;

    }
  }
}

.tik4-we-toggle__label__text {
  display: block;
  font-size: var(--t4-txt-sm);
  line-height: 1.43em;
  &:hover {
    cursor: pointer;
    color: var(--t4-secondary);
  }
}

.tik4-we-toggle__label__hint {
  display: block;
  margin-top: .25em;
  font-size: var(--t4-txt-xs);
  color: var(--t4-muted);
}
