.tik4-hint {
  color: var(--t4-muted);
  display: flex;
  align-items: baseline;
  & .tik4-icon {
    margin-right: .2em;
  }
}

.tik4-hint--sm {
  font-size: var(--t4-txt-sm);
}

.tik4-hint--box {
  padding: var(--t4-sp-4);
  padding: var(--t4-sp-4);
  background: var(--t4-sfc-bg);
  border: 1px solid var(--t4-sfc-b);
  border-radius: var(--t4-rounded);
}


.tik4-hint__hl {
  font-weight: var(--t4-fw-b);
}
