.tik4-twlink {
  margin-top: var(--t4-sp-1);
}

.tik4-twlink__l {
  display: inline-flex;
  align-items: baseline;
  color: var(--t4-tw-link);
  &:hover {
    /* color: var(--t4-txt); */
    color: inherit;
  }
  &:active {
    color: var(--t4-tw-link);
  }
}
