.tik4-spin {
  animation: tik4-spin 1s linear infinite;
}

@keyframes tik4-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@keyframes tik4-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}


@keyframes tik4-pulse {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: .4;
  }
}

@keyframes tik4-fade-in-slide-up {
  0% {
    opacity: 0;
    transform: translateY(.5em);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


@keyframes tik4-show-sticky-button {
  0% {
    opacity: 0;
    transform: translateY(-1em);
  }
  100% {
    opacity: 1;
    transform: translateY(0em);
  }
}
