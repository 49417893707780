.tik4-form-field {
  font-family: inherit;
  font-size: inherit;
  background: var(--t4-ff-bg);
  border: 1px solid var(--t4-ff-b);
  border-radius: var(--t4-ff-radius);
  line-height: var(--t4-ff-lh);
  /* padding: var(--t4-sp-1-5) var(--t4-sp-3); */
  padding: .5em 1em;
  display: block;
  width: 100%;
  appearance: none;
  transition: border-color var(--t4-duration) ease-in-out, box-shadow var(--t4-duration) ease-in-out;
  color: inherit;
  box-shadow: var(--t4-ff-sh);
  box-sizing: border-box;
  &::placeholder {
    color: var(--t4-muted);
  }
  &:hover {
    border-color: var(--t4-ff-b-f);
  }
  &:focus {
    border-color: var(--t4-ff-b-f);
    box-shadow: var(--t4-ff-sh-focus);
  }
}

textarea.tik4-form-field {
  resize: vertical;
}

.tik4-form-field--sm {
  /* min-height: calc(1.5em + (2 * var(--t4-sp-1)) + 2px); */
  /* padding: var(--t4-sp-1) var(--t4-sp-2); */
  padding: 0.4em 1em;
  font-size: var(--t4-txt-sm);
  /* border-radius: var(--t4-rounded-sm); */
}
