/* tik4 gsb: gaelic-score-board */

.tik4-gsb__ph,
.tik4-gsb__top {
  white-space: nowrap;
  overflow-x: hidden;
  overflow-x: clip;
  text-overflow: ellipsis;
}

.tik4-gsb {
  --tik4-gsb__ti-size: 2em;
  container-type: inline-size;
  container-name: tik4-gsb;
  display: grid;
  /* grid-template-columns: minmax(0, 1fr) fit-content(25%) auto fit-content(25%) minmax(0, 1fr); */
  grid-template-columns: minmax(0, 1fr) var(--tik4-gsb__ti-size) fit-content(25%) auto fit-content(25%) var(--tik4-gsb__ti-size) minmax(0, 1fr);
  border: 1px solid var(--t4-sfc-b);
  border-radius: var(--t4-rounded);
  padding-bottom: var(--t4-sp-6);

  &:after {
    content: '';
    grid-area: 1/1/3/-1;
    background: var(--t4-sfc-bg);
    /* border-bottom: 1px solid var(--t4-sfc-b); */
  }
}

.tik4-gsb--m {
  --tik4-gsb__ti-size: 2.5em;
}

{/* TODO:Timo remove if not existing! */}
.tik4-gsb__ph {
  grid-area: 1/1/2/-1;
  text-align: center;
  color: var(--t4-muted);
  font-size: var(--t4-txt-sm);
  position: relative;
  z-index:1;
  padding: var(--t4-sp-1) var(--t4-ev-p-x);
}

{/* TODO:Timo remove if not existing! */}
.tik4-gsb__top {
  grid-area: 2/1/3/-1;
  font-weight: var(--t4-fw-b);
  text-align: center;
  position: relative;
  z-index:1;
  padding: var(--t4-sp-1) var(--t4-ev-p-x);
  &:not(:first-child) {
    padding-top: 0;
    margin-top: calc(-1 * var(--t4-sp-1));
  }
}


{/* TODO:Timo remove if not existing?! */}
.tik4-gsb__sp {
  padding-top: var(--t4-sp-6);
}

.tik4-gsb__n {
  margin-top: var(--t4-sp-1);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
  align-self: baseline;
}
.tik4-gsb__n--ht {
  grid-area: 5/1/6/3;
  text-align: right;
  padding-left: var(--t4-ev-p-x);
}
.tik4-gsb__n--at {
  grid-area: 5/6/6/-1;
  padding-right: var(--t4-ev-p-x);
}
.tik4-gsb--m .tik4-gsb__n {
  font-size: var(--t4-txt-base);
  font-weight: var(--t4-fw-b);
}

& .tik4-gsb__t__n__sn {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

& .tik4-gsb__t__n__n {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: none;
}

.tik4-gsb__s,
.tik4-gsb__d {
  line-height: 1;
  padding-top: var(--t4-sp-4);
}
.tik4-gsb--e {
  & .tik4-gsb__s,
  & .tik4-gsb__d {
    padding-top: var(--t4-sp-2);
  }
}

.tik4-gsb__s {
  overflow: hidden;
  font-weight: var(--t4-fw-b);
  font-size: var(--t4-txt-xl);
}

.tik4-gsb__s--ht {
  text-align: right;
  grid-area: 4/3/5/4;
}
.tik4-gsb__s--ht,
.tik4-gsb__st--ht {
  padding-right: var(--t4-sp-1);
  padding-left: var(--t4-sp-3);
}

.tik4-gsb__s--at {
  grid-area: 4/5/5/6;
}
.tik4-gsb__s--at,
.tik4-gsb__st--at {
  padding-left: var(--t4-sp-1);
  padding-right: var(--t4-sp-3);
}

/* (g)oals and (p)oints */
.tik4-gsb__s__g,
.tik4-gsb__s__p {
  min-width: 1ch;
  display: inline-block;
  text-align: center;
  &:empty {
    display: none;
  }
}
/* .tik4-gsb__s--ht .tik4-gsb__s__p {
  text-align: left;
}
.tik4-gsb__s--at .tik4-gsb__s__p {
  text-align: right;
} */


.tik4-gsb__d {
  font-weight: var(--t4-fw-b);
  font-size: var(--t4-txt-xl);
  text-align: center;
  grid-area: 4/4/5/5;
}

/* (t)otal */
.tik4-gsb__st {
  text-align: center;
  margin-top: var(--t4-sp-1);
  align-self: baseline;
}

.tik4-gsb__st--ht {
  grid-area: 5/3/6/4;
}
.tik4-gsb__st--at {
  grid-area: 5/5/6/6;
}

/* (t)eam (i)con */
.tik4-gsb__i--ht {
  grid-area: 4/2/5/3;
}
.tik4-gsb__i--at {
  grid-area: 4/6/5/7;
}
.tik4-gsb__i {
  flex: 0 0 var(--tik4-gsb__ti-size);
  height: var(--tik4-gsb__ti-size);
  width: var(--tik4-gsb__ti-size);
}

/* (img) (i)mage(p)lace(h)older */
.tik4-gsb__i__img,
.tik4-gsb__i__iph {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.tik4-gsb__c {
  line-height: 1;
}
/* (c)ards (h)ome (t)eam */
.tik4-gsb__c--ht {
  grid-area: 6/1/7/3;
  direction: rtl;
  text-align: right;
  padding-left: var(--t4-ev-p-x);
}
.tik4-gsb__c--at {
  grid-area: 6/6/7/-1;
  padding-right: var(--t4-ev-p-x);
}




/* responsiveness */
@container tik4-gsb (min-width: 36em) {
  .tik4-gsb__i,
  .tik4-gsb__n {
    align-self: center;
  }
  .tik4-gsb__i--ht {
    grid-area: 4/2/6/3;
  }
  .tik4-gsb__i--at {
    grid-area: 4/6/6/7;
  }

  .tik4-gsb__n {
    margin-top: 0;
  }
  .tik4-gsb__n--ht {
    grid-area: 4/1/6/2;
  }
  .tik4-gsb__n--at {
    grid-area: 4/7/6/-1;
  }

  .tik4-gsb__s.tik4-gsb__s,
  .tik4-gsb__d.tik4-gsb__d {
    padding-top: 0;
  }

  .tik4-gsb__st {
    margin-top: 0;
  }

  .tik4-gsb__c--ht {
    grid-area: 6/1/7/2;
  }
  .tik4-gsb__c--at {
    grid-area: 6/7/7/-1;
  }

  .tik4-gsb__n--ht,
  .tik4-gsb__c--ht {
    padding-right: var(--t4-sp-2);
  }

  .tik4-gsb__n--at,
  .tik4-gsb__c--at {
    padding-left: var(--t4-sp-2);
  }

  /* if scoreboard has cards */
  .tik4-gsb--c {
    & .tik4-gsb__s,
    & .tik4-gsb__d {
      grid-row-end: 6;
      align-self: center;
    }
    & .tik4-gsb__st {
      grid-row-start: 6;
      grid-row-end: 7;
    }
  }
  .tik4-gsb--empty {
    & .tik4-gsb__s,
    & .tik4-gsb__d {
      grid-row-end: 6;
    }
  }

}


/* empty scoreboard */

.tik4-gsb--empty {
  & .tik4-gsb__st {
    display: none;
  }
  & .tik4-gsb__s,
  & .tik4-gsb__d {
    /* grid-row-end: 6; */
    align-self: center;
    padding-top: 0;
  }
}


/* scoreboard displayed in (e)vent */
.tik4-gsb--e {
  & .tik4-gsb__n {
    font-size: var(--t4-txt-sm);
  }
}


/* MAIN */
.tik4-gsb--m {
  &:not(:first-child) {
    margin-top: var(--t4-sp-8);
  }
  & + div:not(.tik4-gs) {
    margin-top: var(--t4-sp-8);
  }
}

.tik4-live--xs .tik4-gsb--m,
.tik4-event--xs .tik4-gsb--e  {
  & .tik4-gsb__t__n__sn {
    display: none;
  }

  & .tik4-gsb__t__n__n {
    display: block;
  }
}
