a.tik4-button.tik4-button,
button.tik4-button.tik4-button {
  @mixin tik4-button-reset;
  /* @extend %tik4-button-reset; */
  padding: .5em 1em;
  transition: all var(--t4-duration);
  display: inline-flex;
  align-items: baseline;
  font-weight: var(--t4-fw-b);
  color: #FFF;
  border: 1px solid rgba(0,0,0, 0.1);
  border-radius: var(--t4-rounded);
  box-shadow: 0 .125em .125em rgba(0,0,0,0.07), 0 .25em .75em rgba(0,0,0,.12);
  white-space: nowrap;
  text-decoration: none;
  &:disabled {
    opacity: 0.5;
    filter: saturate(0);
    cursor: default;
  }
  &:hover:not(:disabled) {
    filter: var(--t4-hover-saturate);
    color: #FFF;
    text-decoration: none;
  }
  &:active:not(:disabled) {
    filter: var(--t4-active-saturate);
  }
  &:focus-visible {
    outline-color: var(--t4-txt)
  }
  &:before {
    content: '\00a0';
    visibility: hidden;
    text-indent: -999em;
  }
}

/* a.tik4-button, {
  text-decoration: none;
  &:hover {
    color: #FFF;
    text-decoration: none;
  }
} */

a.tik4-button.tik4-button--secondary,
button.tik4-button.tik4-button--secondary {
  background-color: var(--t4-secondary);
}

a.tik4-button.tik4-button--primary,
button.tik4-button.tik4-button--primary {
  background-color: var(--t4-primary);
}

a.tik4-button.tik4-button--outline-link,
button.tik4-button.tik4-button--outline-link {
  color: var(--t4-link);
  background-color: transparent;
  box-shadow: none;
  border-color: var(--t4-sfc-b);
  &:hover:not(:disabled) {
    filter: none;
    color: var(--t4-link-hover);
    border-color: currentColor;
    text-decoration: none;
  }
  &:active:not(:disabled) {
    filter: none;
    color: var(--t4-link);
  }
  &:focus-visible {
    outline-color: var(--t4-txt);
  }
}


a.tik4-button.tik4-button--sm,
button.tik4-button.tik4-button--sm {
  /* min-height: calc(1.5em + (2 * var(--t4-sp-1)) + 2px); */
  /* padding: var(--t4-sp-1) var(--t4-sp-2); */
  padding: 0.4em 1em;
  font-size: var(--t4-txt-sm);
  /* border-radius: var(--t4-rounded-sm); */
}

/* a.tik4-button.tik4-button--txt,
button.tik4-button.tik4-button--txt {
  background-color: var(--t4-txt);
  color: var(--t4-txt--i);
} */
a.tik4-button.tik4-button--txt--l,
button.tik4-button.tik4-button--txt--l {
  background-color: var(--t4-txt--d);
  color: var(--t4-txt--l);
&:hover:not(:disabled) {
   color: var(--t4-link--l);
 }
}
