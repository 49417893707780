.tik4-pending-update {
  height: 0;
  position: sticky;
  top: var(--t4-pu-t, 0);
  z-index: 10;
  text-align: center;
}

button.tik4-pending-update__button.tik4-pending-update__button {
  /* padding: var(--t4-sp-1) var(--t4-sp-3); */
  opacity: 0;
  /* font-size: var(--t4-txt-sm); */
  box-shadow: 0 .125em .125em rgba(0,0,0,0.15), 0 .25em .75em rgba(0,0,0,.2);
  border-radius: 999em;
  animation: tik4-show-sticky-button .3s ease-in-out 0s forwards;
  position: relative;
  top: 2em;
}
