.tik4-we iframe {
  max-width: 100%;
}

.tik4-we--iframe[data-tik4-we-url*="https://www.youtube.com/embed/"] {
  & .tik4-we-default {
    max-width: var(--t4-we-max-width);
  }
  & .tik4-we-default__container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
    & iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      max-height: 100%;
    }
  }
}


/* hl: headline */
.tik4-we__relative-link-hint__hl {
  font-weight: var(--t4-fw-b);
}
