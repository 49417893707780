.tik4-poll {
  padding: var(--t4-sp-3) var(--t4-sp-4);
  border-radius: var(--t4-rounded);
  overflow: hidden;
  border: 1px solid var(--t4-sfc-b);
}




/* (q)uestion */
.tik4-poll__q {

}

/* (q)uestion (c)ontent */
.tik4-poll__q__c {
  @extend %tik4-break-safe;
  font-weight: var(--t4-fw-b);
  font-size: var(--t4-txt-lg);
}



/* (c)ontent */
.tik4-poll__c {
  margin-top: var(--t4-sp-2);
}


/* (o)ption (l)ist */
.tik4-poll__ol {
  display: flex;
  flex-direction: column;
  gap: var(--t4-sp-1);
}

/* (o)ption (b)utton */
button.tik4-poll__ob.tik4-poll__ob {
  @extend %tik4-break-safe;
  position: relative;
  white-space: normal;
  width: 100%;
  display: flex;
  text-align: center;
  justify-content: center;
  padding: .5em calc( (var(--t4-sp-2) - 2px + 2.5em) / 2 );
  &:disabled {
    & .tik4-poll__ob__t {
      opacity: 0.5;
    }
  }
}
/* (t)ext */
.tik4-poll__ob__t {
  min-width: 0;
}
/* (l)oading icon */
.tik4-poll__ob__l {
  position: absolute;
  top: calc(50% - .45em);
  left: calc(50% - .45em);
}





/* (r)esult (l)ist */
.tik4-poll__rl {
  display: flex;
  flex-direction: column;
  gap: var(--t4-sp-1);
}

/* (r)esult (i)tem */
.tik4-poll__ri {
  min-height: 2.625em;
  position: relative;
  display: flex;
  align-items: center;
}

/* (r)esult (p)rogress */
.tik4-poll__rp {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: var(--t4-sfc-b);
  border-radius: var(--t4-rounded);
  min-width: 0.25em;
  opacity: 0.75;
}

/* (r)esult (c)ontent */
.tik4-poll__rc {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 100%;
  min-width: 0;
  position: relative;
  z-index: 2;
}

/* (t)ext */
.tik4-poll__rc__t {
  display: flex;
  /* align-items: baseline; */
  align-items: center;
  padding-left: var(--t4-sp-2);
  column-gap: var(--t4-sp-1);
  min-width: 0;
}

/* (t)ext (c)ontent */
.tik4-poll__rc__t__c {
  @extend %tik4-break-safe;
  font-weight: var(--t4-fw-b);
  min-width: 0;
  padding: calc(.5em + 1px) 0;
}

/* (t)ext (i)con */
.tik4-poll__rc__t__i {
}

/* (v)alue */
.tik4-poll__rc__v {
  font-weight: var(--t4-fw-b);
  min-width: 2.5em;
  text-align: right;
}



.tik4-poll__feedback {
  margin-top: var(--t4-sp-2);
}





/* (f)ooter */
.tik4-poll__f {
  margin-top: var(--t4-sp-2);
  font-size: var(--t4-txt-sm);
  display: flex;
  flex-wrap: wrap;
  column-gap: var(--t4-sp-2);
  color: var(--t4-muted);
}

/* (d)ivider */
.tik4-poll__f__d {
}

/* (v)ote (c)ount */
.tik4-poll__f__vc {
  display: inline
}

/* (c)lose (a)t */
.tik4-poll__f__ca {
  display: inline;
}

/* (e)nded */
.tik4-poll__f__ca__e {
}

/* (d)ate */
.tik4-poll__f__ca__e {

}



.tik4-poll--loading {
  & button.tik4-poll__ob {
    background: var(--t4-sfc-bg);
    border-color: transparent;
    color: transparent;
  }
  & .tik4-poll__f {
    color: transparent;
  }
}


/* (e)rror */
.tik-poll__e {
  padding: var(--t4-sp-1-5) var(--t4-sp-3);
  text-align: center;
}
