.tik4-chapter {
  /* font-size: var(--t4-txt-xl); */
  line-height: var(--t4-txt-xl-lh);
  font-weight: var(--t4-fw-b);
  text-align: center;
  padding: var(--t4-ms-pt) var(--t4-ev-p-x) var(--t4-ms-pb);
  border-top: 1px solid var(--t4-ev-b);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  &:first-child {
    padding-top: 0;
    border-top: 0;
  }
}

h2.tik4-chapter__headline {
  margin: 0;
  min-width: 0;
  font-size: var(--t4-txt-xl);
  line-height: var(--t4-txt-xl-lh);
  @extend %tik4-break-safe;
  padding-left: 1em;
}

.tik4-chapter__headline__icon {
  margin-left: -1em;
  margin-right: .4em;
  display: inline-block;
}
