.tik4-we--spotify {
  & iframe {
    height: 152px;
    width: 100%;
    max-width: var(--t4-we-max-width);
  }
  &[data-tik4-we-url*=playlist],
  &[data-tik4-we-url*=album] {
    & iframe {
      height: 266px;
    }
  }
  &[data-tik4-we-url*=episode] {
    & iframe {
      height: 232px;
    }
  }
  &[data-tik4-we-url*=track] {
    & iframe {
      height: 380px;
    }
  }
}
