.tik4-skeleton-pulse {
  animation: tik4-pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.tik4-skeleton {
  opacity: 0;
  animation: tik4-fade-in 200ms ease-in-out forwards;
  animation-delay: .5s;
}




.tik4-skeleton__round {
  border-radius: var(--t4-rounded);
}



.tik4-skeleton__row {
  display: flex;
}
.tik4-skeleton__grow {
  flex-grow: 1;
}


.tik4-skeleton__h-4 {
  height: 1em;
}

.tik4-skeleton__h-6 {
  height: 1.5em;
}




.tik4-skeleton__bg {
}

.tik4-skeleton__bg--1 {
  background: rgba(128,128,128, 0.1);
}

.tik4-skeleton__bg--2 {
  background: rgba(128,128,128, 0.2)
}

.tik4-skeleton__bg--3 {
  background: rgba(128,128,128, 0.3)
}
