
.tik4-live--invert-time {
  & .tik4-event--sm .tik4-meta__ts {
    /* margin-left: calc(-1*var(--t4-sp-2-5)); */
    transform: translateX(calc(-1*var(--t4-sp-2-5)));
  }
  & .tik4-meta__ts__date {
    padding: 0 var(--t4-sp-2-5);
  }

  & .tik4-meta__ts__time {
    font-size: var(--t4-txt-base);
    background: var(--t4-bg--i);
    color: var(--t4-txt--i);
    text-align: center;
    border-radius: var(--t4-rounded);
    padding: var(--t4-sp-0-5) var(--t4-sp-2-5);
    justify-content: center;
    /* min-width: 7em; */

  }
}
