.tik4-load-more-bottom {
  margin-top: var(--t4-sp-10);
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0 var(--t4-ev-p-x);
}
a.tik4-load-more-bottom__button.tik4-load-more-bottom__button {
  justify-content: center;
}
