.tik4-content-block {
  margin-top: var(--t4-sp-6);
  flex: 0 0 100%;
  max-width: 100%;
  &.tik4-content-block--web-embed {
    & ~ .tik4-content-block--web-embed {
      margin-top: var(--t4-sp-2);
    }
  }
}
