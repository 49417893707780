.tik4-load-error {
  min-height: 10em;
  min-width: var(--t4-min-width);
  display: flex;
  justify-content: center;
  color: var(--t4-txt);
}

.tik4-load-error__container {
  padding: var(--t4-sp-12);
  max-width: 28em;
}

.tik4-load-error__code {
  font-weight: var(--t4-fw-b);
  font-size: 2.25em;
}

.tik4-load-error__content__headline {
  font-weight: var(--t4-fw-b);
}

.tik4-load-error__content__description {
  margin-top: var(--t4-sp-4);
  font-size: var(--t4-txt-sm);
  color: var(--t4-muted);
}
