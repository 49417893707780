/* SimpleWebEmbed */

.tik4-we-simple {
  text-decoration: none;
  max-width: var(--t4-we-max-width);
  display: block;
}

.tik4-we-simple__container {
  transition: all var(--t4-duration);
  border-radius: var(--t4-rounded);
  overflow: hidden;
  border: 1px solid var(--t4-sfc-b);
  color: inherit;
  text-decoration: none;
  &:hover {
    border-color: var(--t4-link);
    /* background-color: var(--t4-we-bg-hover); */
    & .tik4-we-simple__title {
      color: var(--t4-link);
      text-decoration: underline;
    }
  }
}

.tik4-we-simple__content {
  @extend %tik4-break-safe;
}

.tik4-we-simple__access {
  display: none;
}

.tik4-we-simple__kicker {
  display: inline;
}

.tik4-we-simple__title {
  /* transition: all var(--t4-duration); */
  color: var(--t4-txt);
  font-weight: var(--t4-fw-b);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
}

.tik4-we-simple__description {
  color: var(--t4-muted);
  /* margin-top: .375em; */
  font-size: var(--t4-txt-sm);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
}


.tik4-we--youtube {
  & .tik4-we-simple__description {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
  }
}

.tik4-we-simple__meta {
  color: var(--t4-muted);
  /* margin-top: .375em; */
  font-size: var(--t4-txt-sm);
}



/* SimpleWeb Thumb */

.tik4-we-simple--thumb {
  & .tik4-we-simple__content {
    padding: var(--t4-sp-4);
  }
  & .tik4-we-simple__title {
    font-size: var(--t4-txt-lg);
    line-height: var(--t4-txt-lg-lh);
  }
}

.tik4-we-simple__thumb__img {
  max-width: 100%;
  height: auto;
}




/* SimpleWeb Icon */

.tik4-we-simple--icon {
  & .tik4-we-simple__container {
    display: flex;
    padding: var(--t4-sp-4);
  }
  & .tik4-we-simple__content {
    min-width: 0;
  }
}

.tik4-we-simple__icon {
  margin-right: var(--t4-sp-4);
  color: var(--t4-muted);
  flex-shrink: 0;
}

.tik4-we-simple__icon__img {
  max-width: 3em;
}


