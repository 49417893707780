.tik4-quote__blockquote {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  quotes: none;
  background: transparent;

  text-align: center;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:before,
  &:after {
    content: '';
	  content: none;
  }
}

.tik4-quote__text {
  font-size: var(--t4-txt-lg);
  font-style: italic;
  padding: 0 1.4em;
  max-width: 100%;
}


.tik4-quote__text-content {
  display: inline;
}


.tik4-quote__quotation-mark {
  position: relative;
  width: 0;
  font-size: 2.5em;
  height: 0;
  line-height: 0;
  vertical-align: middle;
  display: inline-flex;
  filter: opacity(0.4);
  /* padding-right: .5em; */
  padding-right: .65em;
  margin-right: -.5em;
}

.tik4-quote__quotation-mark--open {
  justify-content: flex-end;
}

.tik4-quote__text--de {
  & .tik4-quote__quotation-mark--open {
    vertical-align: baseline;
    padding-right: .55em;
  }
  /* & .tik4-quote__quotation-mark--close {
    &:before {
    }
  } */
}

/* .tik4-quote__text--en,
.tik4-quote__text--en-gb {
  & .tik4-quote__quotation-mark--open {
    &:before {
    }
  }
  & .tik4-quote__quotation-mark--close {
    &:before {
    }
  }
} */



.tik4-quote__cite {
  @extend %tik4-break-safe;
  display: block;
  margin-top: var(--t4-sp-2);
  color: var(--t4-muted);
  max-width: 100%;
}
