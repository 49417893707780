.tik4-icon {
  transition: color var(--t4-duration);
  width: .9em;
  height: .9em;
  position: relative;
  top: .075em;
  flex: 0 0 .9em;
  display: inline-block;
  vertical-align: middle;
  fill: transparent;
}

.tik4-icon--mr {
  margin-right: .425em;
}

.tik4-icon--sport {
  & path[fill="#19AEFC"] {
    fill: var(--t4-primary);
  }
}
