/* rs = (r)eact (s)elect */

.tik4-rs.tik4-rs {
  max-width: 100%;
}

.tik4-rs__control.tik4-rs__control {
  background: var(--t4-ff-bg);
  border: 1px solid var(--t4-ff-b);
  border-radius: var(--t4-ff-radius);
  line-height: var(--t4-ff-lh);
  transition: border-color var(--t4-duration) ease-in-out, box-shadow var(--t4-duration) ease-in-out;
  box-shadow: var(--t4-ff-sh);

  &:hover {
    border-color: var(--t4-ff-b-f);
  }
  &.tik4-rs__control--is-focused {
    border-color: var(--t4-ff-b-f);
    box-shadow: var(--t4-ff-sh-focus);
  }
}

.tik4-rs__value-container.tik4-rs__value-container {
  padding: .5em calc(1em);

  &.tik4-rs__value-container--is-multi {
    gap: .25em;
    padding: .25em .5em .25em 0.35em;
    & .tik4-rs__input-container,
    .tik4-rs__placeholder {
      padding: .25em calc(1em - 2px - .25em);
    }
  }
}

.tik4-rs__input-container.tik4-rs__input-container {
  margin: 0;
  padding: 0;
  color: var(--t4-txt);
}


.tik4-rs__placeholder.tik4-rs__placeholder {
  margin: 0;
  color: var(--t4-muted);
}

.tik4-rs__i.tik4-rs__i {
  width: 1.2em;
  height: 1.2em;
}
.tik4-rs__i--ci.tik4-rs__i--ci {
  width: 1em;
  height: 1em;
}

/* mv = (m)ulti (v)alue */
.tik4-rs__mv__i.tik4-rs__mv__i {
  width: 1em;
  height: 1em;
}

.tik4-rs__indicator.tik4-rs__indicator {
  cursor: pointer;
  color: var(--t4-muted);
  &:hover {
    color: var(--t4-txt);
  }
}


.tik4-rs__indicator-separator.tik4-rs__indicator-separator {
  background: var(--t4-ff-b);
  &:first-child {
    display: none;
  }
}

.tik4-rs__multi-value.tik4-rs__multi-value {
  font-size: var(--t4-txt-sm);
  margin: 0;
  border-radius: var(--t4-rounded-sm);
  background: var(--t4-sfc-bg);
  border: 1px solid var(--t4-sfc-b);
  color: var(--t4-txt);
}

.tik4-rs__multi-value__label.tik4-rs__multi-value__label {
  font-size: inherit;
  padding: calc(0.25em - 1px) 0.5em;
  color: inherit;
}

.tik4-rs__multi-value__remove.tik4-rs__multi-value__remove {
  background: transparent;
  &:hover {
    cursor: pointer;
    color: var(--t4-link);
    background: transparent;
  }
  &:active {
    color: inherit;
  }
}


/* select dropdown */

.tik4-rs__menu.tik4-rs__menu {
  background: var(--t4-bg);
  border-radius: var(--t4-rounded);
  border: 1px solid var(--t4-ff-b);
  box-shadow: 0 .125em .5em 0 rgb(0,0,0,.2), 0 .125em 1.5em -.125em rgb(0,0,0,.1);
  overflow: hidden;
}

.tik4-rs__option.tik4-rs__option {
  @extend %tik4-break-safe;
  padding: var(--t4-sp-2) var(--t4-sp-3);
}

.tik4-rs__option--is-focused.tik4-rs__option--is-focused {
  /* color: var(--t4-link); */
  background: var(--t4-sfc-bg);
}



/* sm */

.tik4-rs--sm {
  & .tik4-rs__placeholder,
  & .tik4-rs__single-value,
  & .tik4-rs__input-container {
    font-size: var(--t4-txt-sm);
  }
  & .tik4-rs__value-container {
    padding-top: 0.25em;
    padding-bottom: 0.25em;
    &.tik4-rs__value-container--is-multi {
      padding-top: 0.25em;
      padding-bottom: 0.25em;
      & .tik4-rs__input-container,
      & .tik4-rs__placeholder {
        padding: calc(0.125em) calc(1em - .25em);
      }
    }
  }

  & .tik4-rs__control {
    min-height: 0;
  }

  & .tik4-rs__multi-value__label {
    padding-top: calc(0.125em - 1px);
    padding-bottom: calc(0.125em - 1px);
  }

  & .tik4-rs__indicator {
    padding: 0 0.4em;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: var(--t4-txt-sm);
  }
}
