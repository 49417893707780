.tik4-tw {
  /* background */
  --t4-tw-bg--l: #FFFFFF;
  --t4-tw-bg--d: #242322;

  /* text */
  --t4-tw-txt--l: #1C1C1B;
  --t4-tw-txt--d: #FAF9F7;

  /* muted text */
  --t4-tw-muted--l: #706F6D;
  --t4-tw-muted--d: #B8B7B6;

  /* link */
  --t4-tw-link--l: #0085CC;
  --t4-tw-link--d: #00A2FA;

  /* surface */
  --t4-tw-sfc--l: #F5F3F0;
  --t4-tw-sfc--d: #333231;

  /* live indicator */
  --t4-tw-lic--l: #D93025;
  --t4-tw-lic--d: #D93025;
}
