

.tik4-comments__header__label {
  font-size: var(--t4-co-txt);
  font-weight: var(--t4-fw-b);
  display: flex;
  align-items: center;
}
.tik4-comments__header__label__icon {
  flex: 0 0 1em;
  max-width: 1em;
  height: 1em;
  margin-right: .3em;
  fill: transparent;
}

@keyframes tik4-loading-comments {
  0% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.tik4-more-comments {
  margin-top: var(--t4-sp-3);
  text-align: center;
}

.tik4-more-comments__button {
  @mixin tik4-button-reset;
  /* @extend %tik4-button-reset; */
  color: var(--t4-link);
  font-size: var(--t4-co-txt);
  &:hover {
    color: inherit;
  }
}

.tik4-comment {
  margin-top: var(--t4-sp-3);
}

.tik4-comment__author-thumb {

}

.tik4-comment__author-thumb__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
  display: block;
  border-radius: 999em;
  position: relative;
  z-index: 1;
}

/* .tik4-comment__body, */
.tik4-comment__container,
.tik4-comment__reply-container,
.tik4-comments__loader {
  background: var(--t4-sfc-bg);
  border-radius: var(--t4-co-radius);
  border: 1px solid transparent;
  padding: 0 var(--t4-sp-3) var(--t4-sp-2);
  position: relative;
}

.tik4-comments__loader {
  font-size: var(--t4-txt-sm);
  color: var(--t4-muted);
  animation: tik4-loading-comments .75s ease-in-out 0s infinite alternate;
  text-align: center;
  height: 3em;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: var(--t4-sp-2);
}

.tik4-comment__body {
  min-width: 0;
  padding-top: var(--t4-sp-2);
}

.tik4-comment__container {
  display: grid;
  grid-template-columns: auto 1fr;
  /* align-items: baseline; */
}

.tik4-comment__content {
  font-size: var(--t4-co-txt);
}

.tik4-comment__author {
  @extend %tik4-break-safe;
  font-weight: var(--t4-fw-b);
  margin-right: .4em;
  display: inline;
}

.tik4-comment__text {
  @extend %tik4-break-safe;
  display: inline;
}

.tik4-comment__deleted {
  color: var(--t4-muted);
}

.tik4-comment__highlight {
  position: absolute;
  border-top-left-radius: var(--t4-co-radius);
  border-bottom-left-radius: var(--t4-co-radius);
  overflow: hidden;
  width: .5em;
  left: 0;
  top: 0;
  bottom: 0;
  &:before {
    content: '';
    display: block;
    height: 100%;
    width: .2em;
    background-color: var(--t4-ev-h-b-bg);
  }
}

.tik4-comment__footer {
  color: var(--t4-muted);
  font-size: var(--t4-txt-sm);
  display: flex;
  align-items: baseline;
  justify-content: space-between;

  grid-row-start: 2;
}

.tik4-comment__footer__left {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;
}

.tik4-comment__footer-divider {
  &:before {
    content: "\00B7";
    margin: 0 .5em;

  }
}


.tik4-comment__reply {
  margin-top: var(--t4-sp-2);
  padding-left: var(--t4-sp-3);
  display: flex;
  /* align-items: baseline; */
}

.tik4-comment__author-thumb {
  width: 1.5em;
  height: 1.5em;
  flex: 0 0 1.5em;
  margin-right: var(--t4-sp-2);
  position: relative;

  top: var(--t4-sp-2);
  grid-row: 1 / span 99;
}

.tik4-comment__author-thumb--reply {
  &:before {
    content: '';
    display: block;
    position: absolute;
    left: 50%;
    bottom: 50%;
    border-left: 1px solid var(--t4-sfc-b);
    border-bottom: 1px solid var(--t4-sfc-b);
    right: calc(-1 * var(--t4-sp-2));
    top: calc( -2 * ( var(--t4-sp-2)  ));
  }
}
.tik4-comment__author-thumb--reply-placeholder {}


.tik4-comment__reply-container {
  background-color: transparent;
  border: 1px solid var(--t4-sfc-b);
  flex: 1 1 0;
  min-width: 0;
}









.tik4-comments__write {
  margin-top: var(--t4-sp-3);
}

.tik4-comments__write__button {
  /* color: var(--t4-muted); */
  border-radius: var(--t4-co-radius);
  font-size: var(--t4-co-txt);
  text-align: left;
  cursor: text;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  /* position: relative;
  &:before {
    content: '';
    transition: opacity var(--t4-duration);
    position: absolute;
    right: .6em;
    top: 50%;
    transform: translateY(-50%);
    width: 1em;
    height: 1em;
    background: no-repeat center;
    background-size: contain;
    background-image: var(--t4-icon-send);
    opacity: 0.6;
  } */
  &:hover {
    & .tik4-comments__write__button__icon {
      color: inherit;
    }
  }
}

.tik4-comments__write__button__placeholder {
  color: var(--t4-muted);
}

.tik4-comments__write__button__icon {
  color: var(--t4-muted)
}

.tik4-comments__write__hint {
  margin-top: .5em;
}
