@media screen and (min-width: 40em) {
  .sm\:tik4-modal--right {
    & .tik4-modal__overlay--comments.tik4-modal__overlay--comments {
      background: transparent;
      left: auto;
    }
    & .tik4-modal__content--comments.tik4-modal__content--comments {
      box-shadow: 0 .2em 2em rgba(0,0,0,0.2);
      min-width: 384px; /* if 1rem < 16px */
      width: 24em;
      /* max-width: 30rem; */
    }
  }
}

@media screen and (min-width: 62.5em) {
  .lg\:tik4-modal--right {
    & .tik4-modal__content--comments.tik4-modal__content--comments {
      min-width: 448px; /* if 1rem < 16px */
      width: 28em;
    }
  }
}



.tik4-comment-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
}

.tik4-comment-modal__body {
  flex-grow: 1;
  overflow: auto;
  padding: 0 var(--t4-sp-6) var(--t4-sp-6);
  position: relative;
  display: flex;
  flex-direction: column;
}

.tik4-comment-modal__body__container {
  flex-grow: 1;
}

.tik4-create-comment {
  padding: 0 var(--t4-sp-6);
  margin-top: var(--t4-sp-2);
  margin-bottom: var(--t4-sp-2);
}

.tik4-create-comment__name-group {
  margin-bottom: var(--t4-sp-2-5);
}

.tik4-create-comment__name-form-group {
  display: flex;
  align-items: baseline
}

.tik4-create-comment__name-label {
  font-size: var(--t4-co-txt);
  color: var(--t4-muted);
  white-space: nowrap;

  flex: 0 0 0;
  width: 0;
  position: relative;
  left: calc(var(--t4-sp-3) + 1px);
}

input.tik4-create-comment__name-field {
  border-radius: var(--t4-co-radius);
  padding-left: calc(2 * var(--t4-sp-3) + 1px + 2.5 * var(--t4-co-txt));
}

.tik4-create-comment__name-field,
.tik4-create-comment__comment {
  border-radius: var(--t4-co-radius);
  font-size: var(--t4-co-txt);
}


.tik4-create-comment__comment {
  padding-right: calc(2 * 1em + 1em + 0.26em * 2);
  /* min-height: 4em; */
  min-height: 5.4em;
  /* border-radius: var(--t4-co-radius); */
  max-height: max(10em, 30vh);
}

textarea.tik4-create-comment__comment {
  resize: none;
}

.tik4-create-comment__warning {
  margin-top: var(--t4-sp-1);
}

.tik4-create-comment__comment-submit {
  position: relative;
}

button.tik4-create-comment__submit.tik4-create-comment__submit {
  border-radius: var(--t4-co-radius);
  justify-content: center;
  align-items: center;
  font-size: var(--t4-co-txt);
  position: absolute;
  right: .26em;
  bottom: .27em;
  /* height: min(50%, 2.75rem); */
  /* max-height: calc(var(--t4-co-txt) * var(--t4-ff-lh) + 2 * var(--t4-sp-1-5) - 2px - 0.27em); */
  /* max-height: calc(var(--t4-co-txt) * var(--t4-ff-lh) + 2 * var(--t4-sp-1-5) + 2px - 2*0.27em); */
  /* padding: 0; */
  & .tik4-icon {
    top: 0;
  }
}

.tik4-create-comment__in-approval {
  border-radius: var(--t4-co-radius);
  color: var(--t4-txt--i);
  background: var(--t4-bg--i);
  font-size: var(--t4-txt-sm);
  text-align: center;
  padding: var(--t4-sp-1-5) var(--t4-sp-3);
  margin-top: var(--t4-sp-4);
}

.tik4-create-comment__error {
  font-size: var(--t4-txt-sm);
  color: var(--t4-txt--i);
  background-color: var(--t4-error);
  border-radius: var(--t4-rounded-sm);
  padding: var(--t4-sp-1-5) var(--t4-sp-3);
  margin-bottom: var(--t4-sp-2);
  text-align: center;
}


.tik4-create-comment__count {
  color: var(--t4-muted);
  font-size: var(--t4-txt-xs);
  text-align: right;
  margin-top: var(--t4-sp-1-5);
  display: none;
}

.tik4-create-comment__count--reached-half {
  display: block;
}
.tik4-create-comment__count--reached-almost {
  color: var(--t4-warn);
}
.tik4-create-comment__count--reached-limit {
  color: var(--t4-warn);
}






.tik4-comments__list--modal {
  margin-top: var(--t4-sp-6);
}



.tik4-new-comments {
  position: sticky;
  top: 0;
  z-index: 1;
  height: 0;
  text-align: center;
}

button.tik4-new-comments__button.tik4-new-comments__button {
  /* padding: var(--t4-sp-0-5) var(--t4-sp-2); */
  opacity: 0;
  transform: translateX(-50%);
  position: relative;
  top: .7em;
  /* font-size: var(--t4-txt-sm); */
  box-shadow: 0 .125em .125em rgba(0,0,0,0.15), 0 .25em .75em rgba(0,0,0,.2);
  border-radius: 999em;
  animation: tik4-show-sticky-button .3s ease-in-out 0s forwards;
}


.tik4-no-comments {
  margin-top: var(--t4-sp-12);
  color: var(--t4-muted);
  text-align: center;
}





.tik4-comment-skeleton {
  padding-top: var(--t4-sp-2);
  height: 100%;
  overflow: hidden;
  & .tik4-skeleton__bg--1 {
    padding: 0 var(--t4-sp-1) var(--t4-sp-1);
  }
  & .tik4-skeleton__round {
    margin-top: var(--t4-sp-1);
  }
  & .tik4-skeleton__bg--3 {
    margin-right: var(--t4-sp-1);
  }
}
/* showing only three skeleton comments at mobile since otherwise it will fill full height of screen which looks weird when it shrinks after comments have been loaded */
.tik4-comment-skeleton___comment {
  &:nth-child(n+4) {
    display: none;
  }
  @media screen and (min-width: 40em) {
    &.tik4-comment-skeleton___comment {
      display: block;
    }
  }
}




.tik4-comments__powered-by {
  justify-content: center;
  display: flex;
  align-items: baseline;
  margin-top: var(--t4-sp-12);
  font-size: var(--t4-txt-xs);
  color: var(--t4-muted);
  text-decoration: none;
  opacity: 0.5;
  transition: all var(--t4-duration);
  & svg {
    transition: all var(--t4-duration);
    margin-right: var(--t4-sp-1);
    width: 4.27em;
    height: 0.93em;
  }
  &:hover {
    opacity: 1;
    & .tik4-by__logo {
      color: #343434;
    }
  }
}
