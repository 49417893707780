.tik4-twli {
  color: var(--t4-tw-lic);
  display: flex;
  align-items: baseline;
  gap: 0.25em;
  font-weight: var(--t4-fw-b);
  font-size: var(--t4-txt-sm);
  white-space: nowrap;
}

.tik4-twli__i {
  background: currentColor;
  border-radius: 99em;
  display: inline-block;
  height: .525em;
  position: relative;
  width: .525em;
  top: -.05em;
  &:before {
    animation: t4-li-ping 2s cubic-bezier(0,0,.2,1) infinite;
    background-color: currentColor;
    border-radius: 99em;
    bottom: 0;
    content: "";
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}


@keyframes t4-li-ping {
  50% {
    opacity: 1;
    transform: scale(1);
  }
  90%,100% {
    opacity: 0;
    transform: scale(2);
  }
}

.tik4-tw--li-badge {
  & .tik4-twli {
    background: var(--t4-tw-lic);
    color: #FFF;
    padding-left: .3em;
    padding-right: .3em;
    border-radius: .2em;
    text-transform: uppercase;
    font-size: var(--t4-txt-xs);
  }
  & .tik4-twli__i {
    display: none;
  }
}

