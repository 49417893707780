.tik4-loading-top {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--t4-sp-6);
  padding: 0 var(--t4-ev-p-x);
  &:not(:first-child) {
    margin-top: var(--t4-sp-6);
  }

  & + .tik4-event-list .tik4-chapter:first-child {
    padding-top: calc(var(--t4-ms-pt) - var(--t4-sp-6));
  }
}

a.tik4-loading-top__button.tik4-loading-top__button {
  justify-content: center;
  /* margin-top: -1px; */
  &:hover {
    position: relative;
  }
  /* border-radius: 0;
  &:first-child {
    border-top-left-radius: var(--t4-rounded);
    border-top-right-radius: var(--t4-rounded);
  }
  &:last-child {
    border-bottom-left-radius: var(--t4-rounded);
    border-bottom-right-radius: var(--t4-rounded);
  } */
}

a.tik4-loading-top__button--load-more.tik4-loading-top__button--load-more {
  margin-top: var(--t4-sp-2);
}
